import { useEffect, useState } from "react";
import QuestionService from "../../api/question-service";
import { Link, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

export function FeedbackReport({ questionId, workoutId }) {
    const [ reportShown, setReportShown ] = useState(false);
    const [ comment, setComment ] = useState('');
    const [ reportSent, setReportSent ] = useState(false);
    const [ sendingReport, setSendingReport ] = useState(false);

    useEffect(() => {
        setReportShown(false);
        setReportSent(false);
        setSendingReport(false);
        setComment('');
    }, [ questionId, workoutId ]);

    function submitFeedback() {
        console.log(`Submitting feedback: ${ comment }`);
        setSendingReport(true);

        QuestionService.giveFeedback(
            { questionId: questionId, workoutId: workoutId, complaint: true, comment: comment },
            () => {
                console.log('Feedback submitted');
                setReportSent(true);
                setSendingReport(false);
            },
            () => {
                console.log('Feedback submission failed');
                setSendingReport(false);
            }
        );
    }

    return <>
        <Typography sx={ { mt: '20px' } }>
            Is something wrong with that question?

            {
                !reportShown ?
                    <Link component="button"
                          onClick={ () => setReportShown(true) }
                          sx={ {
                              ml: '10px',
                              color: 'white',
                              textDecorationColor: 'rgba(255, 255, 255, 0.5)',

                              '&:hover': {
                                  textDecorationColor: 'white',
                              }
                          } }
                    >
                        Report that!
                    </Link>
                    :
                    <></>
            }
        </Typography>

        {
            reportShown && !reportSent ?
                <>
                    <TextField multiline
                               label="How to improve it?"
                               variant="filled"
                               minRows="4"
                               value={ comment }
                               onChange={ event => setComment(event.target.value) }
                               sx={ {
                                   color: 'black',
                                   backgroundColor: 'rgba(255, 255, 255)',
                                   mt: '25px',
                                   mb: '10px',
                                   width: '100%',

                                   '&:focus': {
                                       borderColor: 'white',
                                   },

                                   '& label.Mui-focused': {
                                       color: 'black',
                                   },

                                   '& .MuiFilledInput-root': {
                                       '&:after': {
                                           borderBottom: 'none !important',
                                       },

                                       '&.Mui-focused fieldset': {
                                           borderColor: 'black',
                                       },
                                   }
                               } }
                    />

                    <LoadingButton
                        variant="outlined"
                        loading={ sendingReport }
                        sx={ {
                            mr: '10px',

                            color: 'rgba(255, 255, 255, 0.75)',
                            borderColor: 'rgba(255, 255, 255, 0.75)',

                            '&:hover': {
                                color: 'rgba(255, 255, 255, 1)',
                                borderColor: 'rgba(255, 255, 255, 1)'
                            },

                            '&.Mui-disabled': {
                                color: 'rgba(255, 255, 255, 0.5)',
                                borderColor: 'rgba(255, 255, 255, 0.5)'
                            },

                            '&.MuiLoadingButton-loading .MuiCircularProgress-svg': {
                                color: 'white !important'
                            },

                            '&.MuiLoadingButton-loading': {
                                color: 'rgba(255, 255, 255, 0)',
                            }
                        } }
                        disabled={ comment.length === 0 }
                        color="error"
                        endIcon={ <SendIcon/> }
                        onClick={ _ => submitFeedback() }
                    >
                        Send
                    </LoadingButton>

                    <Typography sx={ { marginTop: '5px', fontSize: '75%' } }>
                        Question id: { questionId }.
                    </Typography>
                </> :
                <></>
        }

        {
            reportSent ?
                <Typography>Thank you for feedback.</Typography> :
                <></>
        }
    </>;
}