import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";

export default class ProfileService {
    static getProfile(workoutId: string): Promise<any> {
        return fetch(`${ ENV.BACKEND }/profile/${ workoutId }`, {
            method: 'GET',
            headers: buildHeaders()
        }).then((response) => handleExpiredLogin(response).json());
    }

    static getSelf(): Promise<any> {
        return fetch(`${ ENV.BACKEND }/profile/me`, {
            method: 'GET',
            headers: buildHeaders()
        }).then((response) => handleExpiredLogin(response).json());
    }

    static update(command: ProfileUpdateCommand, successAction, validationFailAction, errorAction): void {
        fetch(`${ ENV.BACKEND }/profile/update`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify(command)
        }).then((response: Response): void => {
            handleExpiredLogin(response);

            if (response.status === 200) {
                response.json().then(successAction);
                return;
            }

            if (response.status === 400) {
                response.json().then(validationFailAction);
                return;
            }

            errorAction()
        }).catch(errorAction);
    }

    static register(command: ProfileRegisterCommand, successAction, validationFailAction, errorAction): void {
        fetch(`${ ENV.BACKEND }/profile/register`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify(command)
        }).then((response: Response): void => {
            handleExpiredLogin(response);

            if (response.status === 200) {
                response.json().then(successAction);
                return;
            }

            if (response.status === 400) {
                response.json().then(validationFailAction);
                return;
            }

            errorAction()
        }).catch(errorAction);
    }
}

export interface ProfileRegisterCommand {
    id: string;
    name: string;
    email: string;
    password: string;
}

export interface ProfileUpdateCommand {
    id: string;
    name: string;
    email: string;
    password: string;
}