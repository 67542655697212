import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={ `/assets/icons/${ name }.svg` } sx={ { width: 1, height: 1 } }/>;

const navConfig = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: icon('dashboard-2'),
        available: true
    },
    {
        title: 'Topics',
        path: '/topics',
        icon: icon('topics'),
        available: false
    },
    {
        title: 'Workouts',
        path: '/workouts',
        icon: icon('workout'),
        available: true
    },
    {
        title: 'Inside [Preview]',
        path: '/inside',
        icon: icon('inside'),
        available: true
    },
    {
        title: 'Profile',
        path: '/profile',
        icon: icon('profile'),
        available: true
    }
].filter((item) => item.available);

export default navConfig;
