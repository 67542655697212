import Header from "../text/Header";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import JOKES from "../../_mock/joke";
import { useMemo, useState } from "react";
import Bubble from "../text/Bubble";
import WorkoutService from "../../api/workout-service";
import { LoadingButton } from "@mui/lab";

export default function FinishedBlock({ show, workout }) {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);

    const joke = useMemo(() => {
        return JOKES[Math.floor(Math.random() * JOKES.length)];
    }, []);

    if (!show) return <></>

    function goHome() {
        navigate('/dashboard');
    }

    function startOneMore() {
        setLoading(true);

        const topics = workout.topics.map(it => it.id);

        console.log("Start new workout for topic: " + topics + " lessons: " + workout.lessons + " tags: " + workout.tags);
        WorkoutService.startNewWorkout(workout.workoutTitle, 5, topics, workout.lessons, workout.tags)
            .then(response => {
                if (!response.id) {
                    alert("Error: Sorry it looks like currently we are not able to start workout for you! Please try again later.");
                    return;
                }

                console.log("Workout started: " + response.id);
                navigate('/workouts/' + response.id, { replace: true, from: '/workouts' });
            })
            .catch(reason => {
                console.error("Unable to start workout: ", reason);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return <>
        <Header type="h3" text="Well Done!"/>

        <Typography sx={ { color: 'text.secondary' } }>
            You just finished your workout. Now you may challenge yourself and start one more or review your
            answers in history.
        </Typography>

        <Box
            component="img"
            src="/assets/icons/love-color.svg"
            sx={ { height: 260, mx: 'auto' } }
        />

        <Bubble>{ joke }</Bubble>

        <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                <LoadingButton fullWidth size="large" variant="contained"
                               onClick={ goHome } loading={ loading }>Go to Home</LoadingButton>
            </Grid>

            <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                <LoadingButton fullWidth size="large" variant="contained"
                               onClick={ startOneMore } loading={ loading }>Start One More</LoadingButton>
            </Grid>
        </Grid>
    </>;
}
