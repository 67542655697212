import { Alert, AlertTitle } from "@mui/material";

export default function FormSavedBanner({ show, message=null }) {
    if (!show) return null;

    message = message || 'Your changes are saved!';

    return <Alert severity="success" variant="outlined"
                  sx={ {
                      backgroundColor: 'rgb(93 171 97)',
                      color: 'rgb(255, 255, 255)',
                      marginTop: '25px',
                      borderColor: '#609540'
                  } }>

        <AlertTitle>Success:</AlertTitle>
        { message }
    </Alert>;
}