import { Alert, AlertTitle } from "@mui/material";

export default function FormErrorBanner({ show, message }) {
    if (!show) return null;

    if (Array.isArray(message)) {
        return <Alert severity="error" variant="outlined">
            <AlertTitle>Error:</AlertTitle>
            <ul>
                { message.map((m, i) => <li key={i}>{ m }</li>) }
            </ul>
        </Alert>;
    }

    return <Alert severity="error" variant="outlined">
        <AlertTitle>Error:</AlertTitle>
        { message }
    </Alert>;
}