import Markdown from "../markdown/Markdown";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, Stack } from "@mui/material";
import UserAnswers from "./UserAnswer";
import OptionButton from "./OptionButton";

export default function QuestionBlock({ question, select, submitted, answer }) {
    function isCorrect(index) {
        if (!submitted) return null;
        return index === question.correct;
    }

    console.log("QuestionBlock", question, answer);

    return <>
        <Markdown text={ question.text }/>
        <Divider>Options (select one):</Divider>

        <List>
            {
                question.options.map((option, index) => (
                    <ListItem key={ `${ question?.id }-${ index }` } onClick={ () => submitted || select(index) }>
                        <ListItemButton>
                            <ListItemIcon>
                                <OptionButton
                                    selected={ index === answer }
                                    correct={ isCorrect(index) }/>
                            </ListItemIcon>

                            <Stack spacing={ 1 }>
                                <Markdown text={ option }/>
                                <UserAnswers question={ question } index={ index } show={ submitted }/>
                            </Stack>

                        </ListItemButton>
                    </ListItem>
                ))
            }
        </List>
    </>;
}