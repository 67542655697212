import { Box } from "@mui/material";

function Bubble({ children }) {
    return <Box
        component="p"
        sx={ {
            // layout
            position: 'relative',
            maxWidth: '30em',

            margin: '0 auto 20px',

            textAlign: 'center',
            backgroundColor: '#fff',
            padding: '1.125em 1.5em',
            fontSize: '1.25em',
            borderRadius: '1rem',
            boxShadow: '0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2)',

            '&::before': {
                content: '""',
                position: 'absolute',
                width: '0',
                height: '0',
                bottom: '100%',
                left: '50%',
                border: '.75rem solid transparent',
                borderTop: 'none',

                // looks
                borderBottomColor: '#fff',
                filter: 'drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1))'
            }
        } }
    >
        { children }
    </Box>;
}

export default Bubble;