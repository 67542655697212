import { Alert, Link } from "@mui/material";
import {
    isNotificationBlocked,
    isNotificationSupported,
    requestNotificationPermission,
    RequestPermissionResultType
} from "../../firebase";
import { useState } from "react";
import NotificationService from "../../api/notification-service";

interface SubscriptionPromptProps {
    show?: boolean;
}

export function SubscriptionPrompt({ show = true }: SubscriptionPromptProps): JSX.Element {
    const [ done, setDone ] = useState(false);
    const [ fail, setFail ] = useState(false);
    const [ denied, setDenied ] = useState(false);

    if (!show || ! isNotificationSupported() || isNotificationBlocked()) return <></>;

    function enableNotificationsAction() {
        requestNotificationPermission(result => {
            switch (result.type) {
                case RequestPermissionResultType.DENIED:
                    setDenied(true);
                    return;
                case RequestPermissionResultType.FAILED:
                    setFail(true);
                    return;
                case RequestPermissionResultType.SUCCESS:
                    NotificationService.subscribeDevice(result.token)
                        .then(result => {
                            if (! result.active) {
                                setFail(true);
                                return;
                            }

                            setDone(true);
                        })
                        .catch(() => {
                            setFail(true);
                        });
                    return;
            }
        });
    }

    if (done) {
        return <Alert severity="success" variant="outlined" style={ { marginBottom: '20px' } }>
            <strong>🎉🎉🎉</strong> You are all set! You will receive notifications every day at 20:00 UTC.
        </Alert>;
    }

    if (fail) {
        return <Alert severity="error" variant="outlined" style={ { marginBottom: '20px' } }>
            <strong>😭😭😭</strong> Something went wrong. Please try again later.
        </Alert>;
    }

    if (denied) {
        return <Alert severity="error" variant="outlined" style={ { marginBottom: '20px' } }>
            <strong>🤔🤔🤔</strong> Ok, ok. Seems it is too soon for you to make the next step in our relationship.
            Take your time.

            <br /><br />

            You have denied notifications. Please enable them in your browser settings.
        </Alert>;
    }

    return <Alert severity="info" variant="outlined" style={ { marginBottom: '20px' } }>
        <strong>Hey! 👋</strong> Would you want to get reminder notifications not to miss your daily workouts?

        <br /><br />

        Just click 🔥 <Link component="button" onClick={enableNotificationsAction}>enable</Link>
        🔥 to get them every day at 20:00 UTC 😊 be a geek, use the correct time!
    </Alert>;
}