import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

export default function App() {
    return <ThemeProvider>
        <ScrollToTop/>
        <StyledChart/>
        <Router/>
    </ThemeProvider>;
}
