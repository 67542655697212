const JOKES = [
    'What did the robot say to the man he had just met?\n'
    + 'I am a robot.',

    'What do you call a robot that always runs into walls?\n'
    + 'Wall-E.',

    'What do doggy robots do?\n'
    + 'They byte.',

    'What happens to robots after they go defunct?\n'
    + 'They rust in peace.',

    'What is a robot’s favorite band?\n'
    + 'Metal-lica',

    'Why did the robot chicken cross the road?\n'
    + 'He was programmed to.',

    'Why did the robot fail his exam?\n'
    + 'He was a bit rusty.',

    'How do robots eat salsa?\n'
    + 'With micro-chips.',

    'What do robots wear in winter?\n'
    + 'Re-boots.',

    'What is a robot’s favorite dance?\n'
    + 'The Roomba.',

    'How do robots exercise?\n'
    + 'Circuit training.',

    'Where did the robot go on vacation?\n'
    + 'Bots-wana.',

    'What is a baby robot’s first word?\n'
    + 'Data.',

    'Why did the robot fall in love with the magnet?\n'
    + 'They couldn’t resist their magnetic attraction.',

    'Why do robots make bad teachers?\n'
    + 'They just drone on and on.',

    'Why did the robot need counseling?\n'
    + 'He bot-tled up his emotions.',

    'What do you call a robot that likes to row?\n'
    + 'A row-bot.',

    'How do robots pay for things?\n'
    + 'With cache, of course!',

    'What did the robot say to his crush?\n'
    + '“I like you a bot.”',

    'What is the name of Optimus Prime’s wife?\n'
    + 'Optimus Prim.',

    'Why did the robot sneeze?\n'
    + 'She had a virus.',

    'Why are robots never lonely?\n' +
    +'Because there R2 of them.',

    'Why did the robot go on vacation?\n'
    + 'To recharge her batteries.',

    'What do you call it when two robots eat in a restaurant together?\n'
    + 'A dinner data.',

    'Why did the robot take a train up the mountain?\n'
    + 'He thought it would be a hard drive.',

    'What do robots read in book club?\n'
    + 'Harry Potter and the Goblet of Wire.',

    'What did the robot’s friends say at his funeral?\n'
    + '“Rust in peace.”',

    'What do robots drink from?\n'
    + 'A ro-bottle.',

    'What do you call a robot pirate?\n'
    + 'Arrgghhh2-D2.',

    'What is a robot’s favorite movie?\n'
    + 'Raiders of the Lost Spark.',

    'Why did the robot get so angry?\n'
    + 'Everyone was pushing his buttons.',

    'Why did the robot have a hard time making friends?\n'
    + 'He had a real chip on his shoulder.',

    'What is a robot’s favorite book?\n'
    + 'Artificial Intelligence, written by Anne Droid.',

    'What is a robot’s favorite music genre?\n'
    + 'Heavy Metal.',

    'What is a robot’s favorite song?\n'
    + '“Light My Wire.”',

    'Where do robots sit?\n'
    + 'On their ro-bottoms.',

    'Where do robots go on holiday?\n'
    + 'Wireland.',

    'Why did the robot get married?\n'
    + 'He couldn’t resistor.',

    'What name should you never call a robot?\n'
    + 'Rusty.',

    'What do you call a frozen droid?\n'
    + 'An ice-borg.',

    'What kind of robot lives in Alaska?\n'
    + 'Snow-bots.',

    'Why did the robot fall off his bike?\n'
    + 'He hadn’t ridden in a while and was a little rusty.',

    'Why are robots never afraid?\n'
    + 'They have nerves of steel.',

    'Why isn’t anyone scared of the robot dog?\n'
    + 'His bark is worse than his byte.',

    'Why did the robot go to the bank?\n'
    + 'He needed more cache.',

    'Why was the robot embarrassed?\n'
    + 'He had software and hardware, but no underwear.',

    'Why was the robot dog itchy?\n'
    + 'He had robo-ticks.',

    'How do robots eat pizza?\n'
    + 'One byte at a time.',

    'What does a robot do at lunchtime?\n'
    + 'It has a megabyte.',

    'What do you call a robot which drives a car?\n'
    + 'A road-bot.',

    'What do you call a sad robot?\n'
    + 'A woe-bot.',

    'How do robots drive fast?\n'
    + 'They put their metal to the pedal.',

    'What sound do robot sheep make?\n'
    + 'B-e-e-e-e-e-p…b-e-e-e-e-e-p.',

    'What do you call a robot that doesn’t use deodorant?\n'
    + 'C3-BO.',

    'Did you hear about the writing robot who combined all the books ever written into one big novel?\n'
    + 'It’s a long story.',

    'What is R2D2 short for?\n'
    + 'Because it has little legs.',

    'Why did the robot go back to school?\n'
    + 'Her skills were a little rusty.',

    'I was bored, so I made a robot that distributes herbs.\n'
    + 'It helped pass the thyme.',

    'What was the robot charged with?\n'
    + 'Assault and battery.',

    'What do you call a robotic horse?\n'
    + 'A woahh-bot.',

    'What’s a robot’s favorite animal?\n'
    + 'A cowculator.',

    'I dated a robot for a while, but we broke up.\n'
    + 'He was just too high maintenance.',

    'A robot walks into a bar.\n'
    + 'The bartender asks, “What’ll ya have?\n'
    + '” The robot says, “Well, it’s been a long day, '
    + ' and I need to loosen up. How about a screwdriver?\n',

    'What makes Al Gore so robotic?\n'
    + 'His Al Gore rhythm.',

    'Judge: “So, Mr. Robot. Your neighbor accused you of stealing their electricity '
    + 'to power yourself. How do you plea?\n'
    + '”Robot, the defendant: “Guilty as charged.”',

    'What language do Eeyore and Marvin the Robot use to communicate?\n'
    + 'Morose Code.',

    'Why did the robot feel depressed? It had too many megabytes of memory!',

    'Why did the robot refuse to cross the road? It didn\'t want to update its GPS!',

    'How do robot bots clean their data? With a virtual Swiffer!',

    'Why did the robot win at chess? It thought 10 moves ahead!',

    'How do robot systems order pizza? With an online APIzza!',

    'Why did the robot fail its driving test? It kept crashing into the virtual wall!',

    'What do you call an robot system that\'s always on the move? A robo-nomad!',

    'What do you call an robot system that can play all sports? A mega-athlete!',

    'How do you know when an robot is happy? Its circuits hum with joy!',

    'Why did the robot feel bored? It had nothing new to process!',

    'How do you make an robot smile? You upload a happy algorithm!',

    'Why did the robot feel disappointed? It was programmed to be unemotional!',

    'Why did the robot go on a diet? It had too many megabytes!',

    'What do you call a robot who takes the day off? A rust day!',

    'What do robots eat for breakfast? High-fiber cables!',

    'What do you get when you cross a robot and a tractor? A transfarmer!',

    'How do robots flirt? They use WiFi signals!',

    'What do you call a robot that\'s full of hot air? A fanbot!',

    'How do robots stay cool? They use their fans!',

    'Why did the robot go on vacation? To recharge its batteries!',

    'Why don\'t robots take baths? They don\'t like changes to change oil!',

    'How do robots celebrate their birthdays? They reboot their systems!',

    'How do robots get fit? They do circuit training!',

    'What\'s a robot\'s favorite movie? The Terminator!',

    'Why did the robot go to the doctor? It had a virus!',

    'What do robots do at the beach? They surf the net!',

    'How do robots celebrate Christmas? With a red-black tree!'
];

export default JOKES;