import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box } from "@mui/material";

export default function Lives({ lives }) {
    if (!lives) return <></>;

    return <Box>
        {
            Array.from({ length: lives }, (_, i) => (
                <FavoriteBorderIcon key={ i } sx={ { height: 30 } }/>
            ))
        }
    </Box>;
}