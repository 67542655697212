import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";

export class WorkoutDefinitionsService {
    static workoutDefinitions() {
        return fetch(`${ ENV.BACKEND }/workout-definitions`, {
            method: 'GET',
            headers: buildHeaders()
        })
            .then((response) => handleExpiredLogin(response).json());
    }
}