import WorkoutUsersBlock from "./WorkoutUsersBlock";
import PageHeader from "../text/PageHeader";
import { useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function WorkoutJoinBlock({ workout, show, joinAction }) {
    const [ loading, setLoading ] = useState(false);

    const topics = useMemo(() => {
        return (workout?.topics || []).map(topic => topic.name || topic.id).join(", ");
    }, [ workout ]);

    if (!show) return <></>;

    function joinActionTrigger() {
        setLoading(true);

        joinAction(() => {
            setLoading(false);
        });
    }

    return <>
        <PageHeader title={ `Join Workout: ${ topics }` }/>

        <Typography sx={ { color: 'text.secondary' } }>
            When you join the workout, you will be able to see the questions and answers of other users, as well as they
            will
            be able to see yours.
        </Typography>

        <LoadingButton variant="contained" loading={ loading }
                       onClick={ joinActionTrigger } sx={ { marginTop: '40px' } }>Join</LoadingButton>

        <WorkoutUsersBlock users={ workout.users } show={ true }/>
    </>;
}