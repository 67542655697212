import { Avatar as BasicAvatar, Tooltip } from '@mui/material';
import palette from "../../theme/palette";

const sizeOptions = {
    default: {},
    xs: { width: 40, height: 40 },
    sm: { width: 60, height: 60 },
    md: { width: 80, height: 80 },
    l: { width: 100, height: 100 },
    xl: { width: 300, height: 300 }
}

const Avatar = ({ userId, show = true, size = 'default', sx = {} }) => {
    if (!show) return <></>

    return <Tooltip title={ `User: ${ userId }` }>
        <BasicAvatar alt="" src={ `https://robohash.org/${ userId }@kata-quiz.com` }
                     sx={ { ...sizeOptions[size], ...sx, bgcolor: palette.grey[300], margin: '10px' } }/>
    </Tooltip>
}

export default Avatar