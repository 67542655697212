import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function WorkoutControlBlock({ submitted, submitAction, nextAction, skipAction, selected }) {
    return <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
            <LoadingButton fullWidth size="large" type="submit"
                           variant="contained" onClick={ submitAction } disabled={ submitted || !selected }>
                Submit
            </LoadingButton>
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
            <LoadingButton fullWidth size="large" type="submit"
                           variant="contained" onClick={ submitted ? nextAction : skipAction }>

                { submitted ? 'Next' : 'Skip' }
            </LoadingButton>
        </Grid>
    </Grid>;
}