import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import ClassIcon from "@mui/icons-material/Class";
import TimelineLessonGroupText from "./TimelineLessonGroupText";
import { Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import TimelineLessonText from "./TimelineLessonText";

export default function TimelineGroup({ group, selectedGroup, groupSwitch, lessonSwitch }) {
    const groupSwitchAction = group.lessons.length ? () => groupSwitch(group) : null;

    return <>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot color={ group.lessons.length ? 'primary' : 'grey' }>
                    <ClassIcon/>
                </TimelineDot>
                <TimelineConnector/>
            </TimelineSeparator>

            <TimelineContent>
                <TimelineLessonGroupText
                    title={ group.title }
                    lessons={ group.lessons.length }
                    onClick={ groupSwitchAction }/>
            </TimelineContent>
        </TimelineItem>

        <Collapse in={ selectedGroup === group }>
            { group.lessons.map(lesson => (
                <TimelineItem key={ lesson.code }>
                    <TimelineSeparator>
                        { lesson.active ? (
                            <TimelineDot color="primary">
                                <AddIcon/>
                            </TimelineDot>
                        ) : (
                            <TimelineDot>
                                <CloseIcon/>
                            </TimelineDot>
                        ) }
                        <TimelineConnector/>
                    </TimelineSeparator>

                    <TimelineContent>
                        <TimelineLessonText title={ lesson.title } shortDescription={ lesson.short || '...' }
                                            onClick={ lesson.active ? () => lessonSwitch(lesson) : null }/>
                    </TimelineContent>
                </TimelineItem>
            )) }
        </Collapse>
    </>;
}