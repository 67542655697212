import { Helmet } from 'react-helmet-async';
import {
    Card,
    Container, Grid, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs,
} from '@mui/material';
import ENV from "../env";
import PageHeader from "../components/text/PageHeader";
import { useEffect, useMemo, useState } from "react";
import { InsideService } from "../api/inside-service";
import 'react-calendar-heatmap/dist/styles.css';
import './InsidePage.css'
import { UserStatisticsService } from "../api/user-statistics-service";

const topics = [
    { title: 'Java', id: 'java' },
    { title: 'AWS', id: 'aws' },
    { title: 'Spring', id: 'spring' },
    { title: 'Kafka', id: 'kafka' },
    { title: 'Linux', id: 'linux' },
    { title: 'Docker & K8s', id: 'docker' },
    { title: 'MongoDB', id: 'mongodb' },
    { title: 'React', id: 'react' },
    { title: 'Angular', id: 'angular' },
    { title: 'TypeScript', id: 'typescript' }
    // {title: 'Python', id: 'python'},
    // {title: 'SQL', id: 'SQL'},
].sort((a, b) => (a.title > b.title) ? 1 : -1);

const tagColumns = [
    { title: 'Tag', id: 'tag' },
    { title: 'Answers', id: 'answers' },
    { title: 'Rate of correct', id: 'rate', postfix: '%' },
    { title: 'Rate of skipped', id: 'skippedRate', postfix: '%' },
];

export default function InsidePage() {
    const [ selectedTopicId, setSelectedTopicId ] = useState(topics[0].id);
    const [ selectedTopic, setSelectedTopic ] = useState(topics[0]);
    const [ tags, setTags ] = useState([]);

    useEffect(() => {
        setSelectedTopic(topics.find(topic => topic.id === selectedTopicId));
        setTags([]);

        InsideService.tagsRate(selectedTopicId).then((response) => {
            console.log('Tags rate', response);
            setTags(response);
        });
    }, [ selectedTopicId ]);

    function selectTopic(event, newValue) {
        setSelectedTopicId(newValue);
    }

    let today = new Date();
    let startDate = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());

    return <>
        <Helmet>
            <title>Inside | { ENV.APP_NAME }</title>
        </Helmet>

        <Container>
            <PageHeader title="Tags Knowledge" sx={ { mt: '30px' } } />

            <Card>
                <Grid container sx={ { width: '100%' } }>
                    <Grid s="12" m="4">
                        <Tabs value={ selectedTopicId } orientation="vertical" onChange={ selectTopic }>
                            { topics.map(topic => <Tab key={ topic.id } label={ topic.title } value={ topic.id }/>) }
                        </Tabs>
                    </Grid>

                    <Grid s="12" m="8" sx={ { px: '25px', py: '15px' } }>
                        <PageHeader title={ `${ selectedTopic.title } Learning` }/>

                        { tags && (
                            <Table sx={ { minWidth: 450 } }>
                                <TableHead>
                                    <TableRow>
                                        { tagColumns.map(column => <TableCell
                                            key={ column.id }>{ column.title }</TableCell>) }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    { tags.map(record =>
                                        <TableRow key={ `${ selectedTopicId }-${ record.tag }` }>
                                            { tagColumns.map(column =>
                                                <TableCell
                                                    key={ `${ selectedTopicId }-${ column.id }-${ record.tag }` }>
                                                    { record[column.id] }{ column.postfix }
                                                </TableCell>
                                            ) }
                                        </TableRow>
                                    ) }
                                </TableBody>
                            </Table>
                        ) }
                    </Grid>
                </Grid>
            </Card>
        </Container>
    </>;
}
