import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Link, Typography } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Header from "../components/text/Header";
import ENV from "../env";
import LoginSidePanel from "../components/sides/LoginSidePanel";
import LoginForm from "../components/auth/LoginForm";
import { useNavigate } from 'react-router-dom';

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function LoginPage(): JSX.Element {
    const mdUp: boolean = useResponsive('up', 'md');
    const navigate = useNavigate();

    function showRegister(): void {
        navigate('/register');
    }

    return (
        <>
            <Helmet>
                <title>Login | { ENV.APP_NAME }</title>
            </Helmet>

            <StyledRoot>
                { mdUp && <LoginSidePanel/> }

                <Container maxWidth="sm">
                    <StyledContent>
                        <Header text="Login"/>
                        <LoginForm/>

                        <Typography variant="body2" gutterBottom sx={{ mb: 5, mt: 2 }}>
                            Don’t have an account?

                            <Link component="button" variant="subtitle2" onClick={showRegister} sx={{ ml: 1 }}>
                                Get started
                            </Link>
                        </Typography>
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
