import PageHeader from "../text/PageHeader";
import QuestionBlock from "./QuestionBlock";
import WorkoutUsersBlock from "./WorkoutUsersBlock";
import WorkoutControlBlock from "./WorkoutControlBlock";
import { useEffect, useState } from "react";
import HintBlock from "./HintBlock";
import Lives from "./Lives";
import SubmitBanner from "./SubmitBanner";
import Header from "../text/Header";

export default function WorkoutProcessBlock({ workout, show, answerSubmitAction, finishAction, failedAction }) {
    const [ submitted, setSubmitted ] = useState(false);
    const [ questionIndex, setQuestionIndex ] = useState(0);
    const [ answer, setAnswer ] = useState(-1);
    const [ lives, setLives ] = useState(null);

    useEffect(() => {
        if (!show || !workout) return;

        setLives(workout.currentUser?.lives);

        if (answer !== -1 || submitted) return;

        const previousAnswer = workout.questions[questionIndex + 1]
            ?.answers
            ?.find(answer => answer.user === workout.currentUser.id)
            ?.index;

        setAnswer(previousAnswer ?? -1);
        setSubmitted(previousAnswer !== undefined);
    }, [ workout ]);

    if (!show || !workout) return <></>

    const questionAmount = workout.questions.length;

    const selectOption = (index) => setAnswer(index);

    function skipAction() {
        answerSubmitAction(workout.questions[questionIndex].id, answer, () => {
            setSubmitted(true);
        }, () => {
        });
    }

    function nextAction() {
        if (workout.currentUser?.lives === 0) {
            failedAction();
            return;
        }

        if (questionIndex + 1 >= questionAmount) {
            finishAction();
            return;
        }

        const previousAnswer = workout.questions[questionIndex + 1]
            ?.answers
            ?.find(answer => answer.user === workout.currentUser.id)
            ?.index;

        setAnswer(previousAnswer ?? -1);
        setSubmitted(previousAnswer !== undefined);
        setQuestionIndex(questionIndex + 1);
    }

    function submitAction() {
        answerSubmitAction(workout.questions[questionIndex].id, answer, () => {
            setSubmitted(true);
        }, () => {
        });
    }

    return <>
        <PageHeader title={ `Workout: ${ workout.workoutTitle }` }
                    sx={ { marginBottom: 0 } }>

            Question { questionIndex + 1 } of { questionAmount }
        </PageHeader>

        <Lives lives={ lives }/>

        <Header text={ `Topic: ${workout.questions[questionIndex].topicTitle}` } type="h5"/>

        <QuestionBlock
            question={ workout.questions[questionIndex] }
            select={ selectOption }
            answer={ answer }
            submitted={ submitted }/>

        <WorkoutControlBlock
            submitted={ submitted }
            submitAction={ submitAction }
            nextAction={ nextAction }
            skipAction={ skipAction }
            selected={ answer >= 0 }/>

        <SubmitBanner show={ submitted }
                      questionId={ workout.questions[questionIndex].id }
                      workoutId={ workout.id }
                      correct={ answer === workout.questions[questionIndex]?.correct }/>

        <HintBlock
            text={ workout.questions[questionIndex]?.hint }
            refs={ workout.questions[questionIndex]?.ref }
            show={ submitted }/>

        <WorkoutUsersBlock users={ workout.users }
                           answers={ workout.questions[questionIndex]?.answers }
                           show={ workout.users?.length > 1 }/>
    </>
}