let isProd = window.location.hostname === 'app.kata-quiz.com';
let isTest = window.location.hostname === 'app.kata.aiskov.com';

const envs = {
    PROD: {
        BACKEND: 'https://api.kata-quiz.com',
        FRONTEND: 'https://app.kata-quiz.com',
        LANDING: 'https://kata-quiz.com',
    },

    TEST: {
        BACKEND: 'https://api.kata.aiskov.com',
        FRONTEND: 'https://app.kata.aiskov.com',
        LANDING: 'https://kata.aiskov.com',
    },

    LOCAL: {
        BACKEND: 'http://localhost:8101',
        FRONTEND: 'http://localhost:3000',
        LANDING: 'http://localhost:3000',
    }
}

const ENV = {
    ...envs[isProd ? 'PROD' : (isTest ? 'TEST' : 'LOCAL')],

    APP_NAME: isProd || isTest ? 'Kata Quiz' : 'Kata Quiz Dev',
    IS_PROD: isProd || isTest
}

export default ENV;