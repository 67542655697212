import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
