import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import SvgColor from "../svg-color";
import useAuth from "../../hooks/useAuth";

NavSection.propTypes = {
    data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
    let logout = {
        title: 'Logout',
        path: '/login',
        icon: <SvgColor src={ `/assets/icons/logout.svg` } sx={ { width: 1, height: 1 } }/>,
    };

    const auth = useAuth();

    return (
        <Box { ...other }>
            <List disablePadding sx={ { p: 1 } }>
                { data.map((item) => (
                    <NavItem key={ item.title } item={ item }/>
                )) }

                <NavItem item={ logout } onClick={ () => auth.logout() }/>
            </List>
        </Box>
    );
}

NavItem.propTypes = {
    item: PropTypes.object,
};

function NavItem({ item, onClick, }) {
    const { title, path, icon, info } = item;

    return <StyledNavItem
        component={ RouterLink }
        to={ path }
        sx={ {
            '&.active': {
                color: 'text.primary',
                bgcolor: 'action.selected',
                fontWeight: 'fontWeightBold',
            },
        } }
        onClick={ onClick || (() => {
        }) }
    >
        <StyledNavItemIcon>{ icon && icon }</StyledNavItemIcon>

        <ListItemText disableTypography primary={ title }/>

        { info && info }
    </StyledNavItem>;
}
