import Header from "../text/Header";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function FailureBlock({ show }) {
    const navigate = useNavigate();

    if (!show) return <></>

    function goHome() {
        navigate('/dashboard');
    }

    return <>
        <Header type="h3" text="Oh no!"/>

        <Typography sx={ { color: 'text.secondary' } }>
            Seems today is not your day. Let's try it tomorrow!
        </Typography>

        <Box
            component="img"
            src="/assets/icons/sad.svg"
            sx={ { height: 260, mx: 'auto', my: { xs: 5, sm: 10 } } }
        />

        <Button size="large" variant="contained" onClick={ goHome }>Go to Home</Button>
    </>;
}
