import PropTypes from 'prop-types';
import { Box, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

UserListHead.propTypes = {
    order: PropTypes.oneOf([ 'asc', 'desc' ]),
    orderBy: PropTypes.string,
    rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    numSelected: PropTypes.number,
};

export default function UserListHead({
                                         order,
                                         orderBy,
                                         headLabel
                                     }) {
    return (
        <TableHead>
            <TableRow>
                { headLabel.map((headCell) => (
                    <TableCell
                        key={ headCell.id }
                        align={ headCell.alignRight ? 'right' : 'left' }
                        sortDirection={ orderBy === headCell.id ? order : false }
                    >
                        <TableSortLabel hideSortIcon>
                            { headCell.label }
                        </TableSortLabel>
                    </TableCell>
                )) }
            </TableRow>
        </TableHead>
    );
}
