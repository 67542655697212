import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";
import { v4 as uuidV4 } from 'uuid';

export default class NotificationService {
    static subscribeDevice(token: string): Promise<any> {
        const request: DeviceSubscriptionRequest = {
            deviceId: NotificationService.deviceId(),
            deviceName: createAgentSimplifiedName(),

            token
        };

        return fetch(`${ ENV.BACKEND }/notification/subscribe-device`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify(request)
        }).then((response) => handleExpiredLogin(response).json());
    }

    static checkIfDeviceSubscribed(): Promise<any> {
        const request: CheckSubscription = { deviceId: NotificationService.deviceId() };

        return fetch(`${ ENV.BACKEND }/notification/check-if-device-subscribed`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify(request)
        }).then((response) => handleExpiredLogin(response).json());
    }

    static deviceId(): string {
        let item = localStorage.getItem("deviceId");

        if (!item) {
            item = uuidV4();
            localStorage.setItem("deviceId", item);
        }

        return item;
    }
}

export interface CheckSubscription {
    deviceId: string;
}

export interface DeviceSubscriptionRequest {
    deviceId: string;
    deviceName: string;

    token: string;
}

function createAgentSimplifiedName() {
    return `${ getBrowserFromAgent() } in ${ getDeviceTypeFromAgent() }`;
}

function getDeviceTypeFromAgent(): string {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('iPhone') >= 0) {
        return 'iPhone';
    } else if (userAgent.indexOf('iPad') >= 0) {
        return 'iPad';
    } else if (userAgent.indexOf('Android') >= 0) {
        return 'Android';
    } else if (userAgent.indexOf('Windows Phone') >= 0) {
        return 'Windows Phone';
    } else if (userAgent.indexOf('Linux') >= 0) {
        return 'Linux';
    } else if (userAgent.indexOf('Windows NT') >= 0) {
        return 'Windows';
    } else if (userAgent.indexOf('Macintosh') >= 0) {
        return 'Mac';
    }

    return 'Unknown Device';
}

function getBrowserFromAgent(): string {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Chrome') >= 0) {
        return 'Chrome';
    } else if (userAgent.indexOf('Firefox') >= 0) {
        return 'Firefox';
    } else if (userAgent.indexOf('Safari') >= 0) {
        return 'Safari';
    } else if (userAgent.indexOf('Edge') >= 0) {
        return 'Edge';
    } else if (userAgent.indexOf('Trident') >= 0) {
        return 'Internet Explorer';
    } else if (userAgent.indexOf('Opera') >= 0) {
        return 'Opera';
    }

    return 'Unknown Browser';
}