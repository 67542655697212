import { Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom';
import MainLayout from './layouts/main';
import SimpleLayout from './layouts/simple';
import TopicsPage from './pages/TopicsPage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardPage from './pages/DashboardPage';
import WorkoutPage from "./pages/WorkoutPage";
import WorkoutsPage from "./pages/WorkoutsPage";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import TopicPage from "./pages/TopicPage";
import InsidePage from "./pages/InsidePage";
import AuthService from "./api/auth-service";
import RegisterPage from "./pages/RegisterPage";

export const publicRoutes = [
    '/login',
    '/register',
    '/404'
];

export default function Router() {
    const auth: AuthService = useAuth();
    const location = useLocation();

    function authorizedRoute(path: string, element: JSX.Element, index: boolean = false): RouteObject {
        if (auth.isLoggedIn()) return { path, element, index };
        return { path, element: <Navigate to="/login"/> };
    }

    useEffect(() => {
        const path = location.pathname;

        if (!auth.isLoggedIn() && !publicRoutes.includes(path)) {
            console.log("Redirect to login from " + path);
            sessionStorage.setItem('login-redirect', path);
        }

        console.log("Move to: ", path);
    }, [ location ])

    return useRoutes([
        {
            path: '/',
            index: true,
            element: <Navigate to="/dashboard"/>,
        },

        {
            element: <MainLayout/>,
            children: [
                authorizedRoute('/dashboard', <DashboardPage/>, true),
                authorizedRoute('/topics', <TopicsPage/>),
                authorizedRoute('/topics/:id', <TopicPage/>),
                authorizedRoute('/workouts', <WorkoutsPage/>),
                authorizedRoute('/inside', <InsidePage/>),
                authorizedRoute('/profile', <ProfilePage/>),
                authorizedRoute('/profile/:id', <ProfilePage/>),
            ]
        },

        authorizedRoute('/workouts/:id', <WorkoutPage/>),

        {
            path: '/login',
            element: <LoginPage/>
        },

        {
            path: '/register',
            element: <RegisterPage/>
        },

        {
            element: <SimpleLayout/>,
            children: [
                { element: <Navigate to="/dashboard"/>, index: true },
                { path: '/404', element: <Page404/> },
                { path: '/*', element: <Navigate to="/404"/> }
            ],
        },

        {
            path: '*',
            element: <Navigate to="/404"/>
        },
    ]);
}
