import CalendarHeatmap from 'react-calendar-heatmap';
import { UserStatisticsService } from "../../../api/user-statistics-service";
import { useMemo, useState } from "react";
import { Box, Card, CardHeader } from "@mui/material";

export default function UserActivities({ title, ...other }) {
    const [ calendarData, setCalendarData ] = useState([]);

    let today: Date = new Date();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 1.2 * 365);
    startDate.setDate(startDate.getDate() - startDate.getDay())

    useMemo(() => {
        UserStatisticsService.userCalendar().then((response) => {
            setCalendarData(response?.items.map(item => {
                return {
                    date: item.date,
                    count: item.workouts
                };
            }));
        });
    }, [ today ])

    return <>
            <Card { ...other }>
                <CardHeader title={ title } subheader="" />

                <Box sx={ { p: 3, pb: 1 } } dir="ltr">
                    <CalendarHeatmap
                        startDate={startDate}
                        endDate={today}
                        values={calendarData}
                        titleForValue={value => value?.count ? `${value.date}: ${value.count} workout(s)` : 'No workouts'}
                        tooltipDataAttrs={value => {
                            return {
                                'data-tooltip': value?.count ? `${value.date}: ${value.count} workout(s)` : 'No workouts'
                            };
                        }}
                        classForValue={value => {
                            if (! value || ! value.count) return 'color-empty';
                            if (value.count < 3) return `color-scale-1`;
                            if (value.count < 5) return `color-scale-2`;
                            if (value.count < 7) return `color-scale-3`;
                            return `color-scale-4`;
                        }} />
                </Box>
            </Card>
        </>;
}
