import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { styled } from '@mui/styles';
import useResponsive from '../hooks/useResponsive';
import ENV from "../env";
import WorkoutService from "../api/workout-service";
import FinishedBlock from "../components/workouts/FinishedBlock";
import WorkoutProcessBlock from "../components/workouts/WorkoutProcessBlock";
import WorkoutJoinBlock from "../components/workouts/WorkoutJoinBlock";
import { useEffect, useState } from "react";
import WorkoutSidePanel from "../components/sides/WorkoutSidePanel";
import FailureBlock from "../components/workouts/FailuredBlock";

const PageRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const MainContent = styled('div')(({ theme }) => (
    {
        margin: 'auto',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(12, 0),
    }
));

let workoutUpdateInterval = null;

export default function WorkoutPage() {
    const [ finished, setFinished ] = useState(false);
    const [ failed, setFailed ] = useState(false);
    const [ workout, setWorkout ] = useState(null);
    const [ joined, setJoined ] = useState(false);

    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate();

    // Load workout data
    const { id } = useParams();

    useEffect(() => {
        if (!id) {
            console.log("Unable to receive workout id");
            navigate('/404');
        }

        setFinished(false);
        setFailed(false);
        setWorkout(null);
        setJoined(false);

        function loadWorkout() {
            WorkoutService.getWorkout(id)
                .then(data => {
                    if (!data.id) {
                        console.log("Unable to load workout by id");
                        if (workoutUpdateInterval) clearInterval(workoutUpdateInterval);
                        navigate('/404');
                        return;
                    }

                    console.log("Workout loaded: ", data);
                    setWorkout(data);

                    data.currentUser && setJoined(true);
                })
                .catch(reason => {
                    console.log("Unable to load workout by id", reason);
                    navigate('/404');
                });
        }

        loadWorkout();
        if (workoutUpdateInterval) clearInterval(workoutUpdateInterval);
        workoutUpdateInterval = setInterval(loadWorkout, 5_000);
    }, [ id ]);

    function answerSubmitAction(questionId, answerId, successCallback, errorCallback) {
        WorkoutService.submitAnswer(workout.id, questionId, answerId)
            .then(result => {
                if (result) {
                    console.log(`Answer to question ${ questionId } submitted.`);
                    setWorkout(result);

                    successCallback();
                    return;
                }

                errorCallback();
            })
            .catch(reason => {
                console.error("Unable to submit answer: " + reason);
                errorCallback();
            });
    }

    function joinAction(callback) {
        WorkoutService.joinWorkout(workout.id)
            .then(result => {
                if (result) {
                    console.log("Workout joined");
                    setWorkout(result);
                    setJoined(true);

                    if (callback) callback();
                    return;
                }

                console.error("Unable to join workout");
            })
            .catch(reason => {
                console.error("Unable to join workout: " + reason);
            });
    }

    function finishAction() {
        clearInterval(workoutUpdateInterval);

        WorkoutService.finishWorkout(workout.id)
            .then(result => {
                if (result) {
                    console.log("Workout finished.");
                    setFinished(true);
                }
            })
            .catch(reason => {
                console.error("Unable to finish workout: " + reason);
            })
    }

    function failedAction() {
        clearInterval(workoutUpdateInterval);

        WorkoutService.finishWorkout(workout.id)
            .then(result => {
                if (result) {
                    console.log("Workout failed.");
                    setFailed(true);
                }
            })
            .catch(reason => {
                console.error("Unable to finish workout: " + reason);
            })
    }

    return <>
        <Helmet>
            <title>Workout | { ENV.APP_NAME }</title>
        </Helmet>

        <PageRoot>
            <WorkoutSidePanel hidden={ !mdUp }></WorkoutSidePanel>
            <Container maxWidth="md">
                { !!workout && (
                    <MainContent>
                        <WorkoutJoinBlock workout={ workout } joinAction={ joinAction } show={ !joined }/>

                        <WorkoutProcessBlock
                            workout={ workout }
                            answerSubmitAction={ answerSubmitAction }
                            finishAction={ finishAction }
                            failedAction={ failedAction }
                            show={ workout && joined && !finished && !failed }/>

                        <FailureBlock show={ workout && failed }/>
                        <FinishedBlock show={ workout && joined && finished } workout={ workout }/>
                    </MainContent>
                ) }
            </Container>
        </PageRoot>
    </>;
}
