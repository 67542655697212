import { alpha, styled } from '@mui/material/styles';
import { Link, Card, CardContent } from '@mui/material';

const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
});

const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

export default function TopicHeader({ topic }) {
    const { cover, title } = topic;

    return (
        <Card sx={ { position: 'relative' } }>
            <StyledCardMedia
                sx={ {
                    ...({
                        pt: 'calc(100% * 4 / 3)',
                        '&:after': {
                            top: 0,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
                        },
                    }),
                    ...({
                        pt: {
                            xs: 'calc(100% * 4 / 3)',
                            sm: 'calc(100% * 1.5 / 4.66)',
                        },
                    }),
                } }
            >

                <StyledCover alt={ title } src={ cover }/>
            </StyledCardMedia>

            <CardContent
                sx={ {
                    pt: 4,
                    ...({
                        bottom: 0,
                        width: '100%',
                        position: 'absolute',
                    }),
                } }
            >
                <StyledTitle
                    color="inherit"
                    variant="subtitle2"
                    underline="hover"
                    sx={ {
                        ...({ typography: 'h5', height: 60 }),
                        ...({
                            color: 'common.white',
                            cursor: 'pointer',
                        }),
                    } }
                >
                    { title }
                </StyledTitle>
            </CardContent>
        </Card>
    );
}
