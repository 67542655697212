import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Collapse, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import useAuth from "../../hooks/useAuth";

export default function LoginForm() {
    const navigate = useNavigate();
    const auth = useAuth();

    const [ loading, setLoading ] = useState(false);
    const [ invalidUsername, setInvalidUsername ] = useState(null);
    const [ invalidPassword, setInvalidPassword ] = useState(null);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ error, setError ] = useState(null);

    const usernameRef = useRef();
    const passwordRef = useRef();

    const loginSuccessEvent = () => {
        console.log("Login success");

        let targetLocation = sessionStorage.getItem('login-redirect') || '/dashboard';
        if (targetLocation === '/login') targetLocation = '/dashboard';
        sessionStorage.removeItem('login-redirect');
        navigate(targetLocation, { replace: true });
    };

    const loginFailedEvent = (error) => {
        setError(error || "Oops! Something went wrong. Please try again.");

        setLoading(false);

        setInvalidUsername("");
        setInvalidPassword("");
    }

    const loginAction = () => {
        let failed = false;

        if (!usernameRef.current.value) {
            setInvalidUsername("Please enter a username");
            failed = true;
        } else {
            setInvalidUsername(null);
        }

        if (!passwordRef.current.value) {
            setInvalidPassword("Please enter a password");
            failed = true;
        } else {
            setInvalidPassword(null);
        }

        if (failed) return;
        setLoading(true);

        auth.login(
            { username: usernameRef.current.value, password: passwordRef.current.value },
            loginSuccessEvent,
            loginFailedEvent
        );
    };

    document.addEventListener('keydown', event => event.key === 'Enter' && loginAction());
    window.addEventListener('load', event => document.getElementsByName('username')[0]?.focus());

    return <>
        <Stack spacing={ 3 }>
            <Collapse in={ !!error }>
                <Alert severity="error">
                    <AlertTitle>Failure</AlertTitle>
                    { error || '' }
                </Alert>
            </Collapse>

            <TextField
                name="username"
                label="Username"
                inputRef={ usernameRef }
                error={ invalidUsername !== null }
                helperText={ invalidUsername }
            />

            <TextField
                name="password"
                label="Password"
                type={ showPassword ? 'text' : 'password' }
                inputRef={ passwordRef }
                error={ invalidPassword !== null }
                helperText={ invalidPassword }
                InputProps={ {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={ () => setShowPassword(!showPassword) } edge="end">
                                <Iconify icon={ showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill' }/>
                            </IconButton>
                        </InputAdornment>
                    ),
                } }
            />

            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={ loading }
                onClick={ loginAction }>

                Login
            </LoadingButton>
        </Stack>
    </>;
}
