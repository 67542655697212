import { Chip, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import Avatar from "./Avatar";

interface UserListParams {
    users: User[],
    answers: Answer[]
}

interface User {
    id: string,
    name: string
}

interface Answer {
    user: string,
    index: number
}

function isUserAnswered(answers: Answer[], userId: string): boolean {
    return !!answers?.find((answer: Answer) => answer.user === userId);
}

export default function UserList(params: UserListParams): JSX.Element {
    return <List>
        {
            params.users?.map((user: User) => (
                <ListItem key={ `user-${ user.id }` }
                          secondaryAction={
                              isUserAnswered(params.answers, user.id) ? <Chip label="Ready" color="primary"/> : <></>
                          }>
                    <ListItemIcon>
                        <Avatar userId={ user.id } size="md"/>
                    </ListItemIcon>

                    <ListItemText primary={ user.name } secondary={ `(${ user.id })` }/>
                </ListItem>
            ))
        }
    </List>;
}