export function buildHeaders(authorized = true) {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };

    if (authorized) {
        headers['Authorization'] = `Bearer ${ localStorage.getItem('auth-token') }`;
    }

    return headers;
}

export function handleExpiredLogin(response) {
    if (response.status === 401) {
        console.log("fail path" + window.location.pathname);
        window.location.reload();
    }

    return response;
}