import Avatar from "../user/Avatar";
import useAuth from "../../hooks/useAuth";

export default function UserAnswers({ question, index, show }) {
    const auth = useAuth();

    if (!show) return <></>

    const users = question.answers
        .filter(answer => answer.index === index)
        .filter(answer => answer.user !== auth.username)
        .map(answer => answer.user);

    return <>
        { users.map(user => <Avatar key={ user } userId={ user }/>) }
    </>
}