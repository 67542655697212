import { Helmet } from 'react-helmet-async';

import { useTheme } from '@mui/material/styles';
import { Collapse, Container, Grid, Typography } from '@mui/material';

import { AppCurrentSubject, AppWebsiteVisits, AppWidgetSummary } from '../sections/@dashboard/app';
import ENV from "../env";
import { UserStatistics, UserStatisticsService } from "../api/user-statistics-service";
import { useEffect, useState } from "react";
import { SubscriptionPrompt } from "../components/notifications/SubscriptionPrompt";
import NotificationService from "../api/notification-service";
import UserActivities from "../sections/@dashboard/app/UserActivities";

export default function DashboardPage() {
    const theme = useTheme();
    const [ stat, setStat ] = useState({} as UserStatistics);
    const [ timeline, setTimeline ] = useState({ labels: [], values: [] });
    const [ topics, setTopics ] = useState({ labels: [], current: [], previous: [] });
    const [ showSubscriptionPrompt, setShowSubscriptionPrompt ] = useState(false);

    useEffect(() => {
        NotificationService.checkIfDeviceSubscribed().then((subscribed) => {
            if (! subscribed.active) setShowSubscriptionPrompt(true);
        });
    }, []);

    useEffect(() => {
        UserStatisticsService.userStatistics()
            .then(data => {
                console.log(data);
                setStat(data);

                // Calculate timeline
                let newTimeline = { labels: [], values: [] };

                Object.entries(data.workoutsByWeek).forEach(([ key, value ]) => {
                    newTimeline.labels.push(key);
                    newTimeline.values.push(value);
                });

                setTimeline(newTimeline);

                // Calculate topics
                let newTopics = { labels: [], current: [], previous: [] };

                data.currentWeek.forEach(value => {
                    newTopics.labels.push(value.name || value.id);
                    newTopics.current.push(value.workouts);
                });

                data.previousWeek.forEach(value => {
                    if (!newTopics.labels.includes(value.name || value.id)) {
                        newTopics.labels.push(value.name || value.id);
                        newTopics.current.push(0);
                    }
                });

                newTopics.labels.forEach((label) => {
                    const foundRecord = data.previousWeek.find(record => record.name === label || record.id === label);
                    newTopics.previous.unshift(foundRecord?.workouts || 0);
                });

                setTopics(newTopics);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Dashboard | { ENV.APP_NAME }</title>
            </Helmet>

            <Container maxWidth="xl">
                <Typography variant="h4" sx={ { mb: 5 } }>
                    Hi, Welcome back!
                </Typography>

                <SubscriptionPrompt show={showSubscriptionPrompt}></SubscriptionPrompt>

                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                        <AppWidgetSummary
                            title="Weekly Questions"
                            total={ stat.weeklyQuestions || 0 }
                            icon={ 'ant-design:question-circle-filled' } />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                        <AppWidgetSummary
                            title="Weekly Workouts"
                            total={ stat.weeklyWorkouts || 0 }
                            color="info"
                            icon={ 'ant-design:flag-filled' }/>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                        <AppWidgetSummary title="Experience Points"
                                          total={ stat.overallExperience || 0 }
                                          color="warning"
                                          icon={ 'ant-design:star-filled' } />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                        <AppWidgetSummary
                            title="Days in Streak "
                            total={ stat.daysInStreak || 0 }
                            tooltip={`You have ${stat.availableFreezes} freezes.`}
                            color="error"
                            icon={ 'ant-design:fire-filled' } />
                    </Grid>

                    <Grid item xs={ 12 } md={ 12 } lg={ 12 }>
                        <UserActivities title="User Activities" />
                    </Grid>

                    <Grid item xs={ 12 } md={ 6 } lg={ 6 }>
                        <Collapse in={ topics.labels.length > 2 }>
                            <AppCurrentSubject
                                title="Topics Practiced"
                                chartLabels={ topics.labels }
                                chartData={ [
                                    { name: 'Current week', data: topics.current },
                                    { name: 'Previous week', data: topics.previous }
                                ] }
                                chartColors={ [ ...Array(2) ].map(() => theme.palette.text.secondary) }
                            />
                        </Collapse>
                    </Grid>

                    <Grid item xs={ 12 } md={ 6 } lg={ 6 }>
                        <Collapse in={ timeline.labels.length > 1 }>
                            <AppWebsiteVisits
                                title="Workouts by weeks"
                                chartLabels={ timeline.labels }
                                chartData={ [
                                    { name: 'Workouts', type: 'area', fill: 'solid', data: timeline.values }
                                ] }
                            />
                        </Collapse>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
