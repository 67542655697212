import { Typography } from "@mui/material";

export default function TimelineLessonGroupText({ title, lessons, onClick = null }) {
    return <>
        <Typography variant="h6" component="span" onClick={ onClick }
                    sx={ { cursor: onClick ? 'pointer' : 'inherit' } }>

            { title }
        </Typography>

        <Typography>
            ({ lessons } lessons)
        </Typography>
    </>;
}