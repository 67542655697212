import { Alert, AlertTitle } from "@mui/material";
import { FeedbackReport } from "./FeedbackReport";
import { RatingReport } from "./RatingReport";

interface SubmitBannerProps {
    show: boolean;
    correct: boolean;
    questionId: string;
    workoutId: string;
}

export default function SubmitBanner({ show, correct, questionId, workoutId }: SubmitBannerProps) {
    if (! show) return <></>

    return <>
        { correct ? (
            <Alert severity="success" variant="filled"
                   sx={ { backgroundColor: 'rgb(46, 125, 50)', color: 'rgb(255, 255, 255)', marginTop: '25px' } }>

                <AlertTitle>Correct</AlertTitle>
                You got it right!

                <RatingReport questionId={ questionId } workoutId={ workoutId } />
            </Alert>
        ) : (
            <Alert severity="error"
                   variant="filled"
                   sx={ {
                       backgroundColor: 'rgb(211, 47, 47)',
                       color: 'rgb(255, 255, 255)',
                       marginTop: '25px',

                       '& .MuiAlert-message': {
                           width: '75%',
                       }
                   } }>

                <AlertTitle>Wrong</AlertTitle>
                Chance to learn something new!

                <FeedbackReport questionId={ questionId } workoutId={ workoutId } />
            </Alert>
        ) }
    </>
}