import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Divider, Drawer, Stack, Typography } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';

import navConfig from './config';
import { styled } from "@mui/styles";

const NAV_WIDTH = 280;

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

const SupportLabel = styled(props =>
    <>
        <Typography {...props}>Support This Project:</Typography>
    </>
)(({ theme }) => (
    {
        color: theme.palette.text.secondary,
        width: '100%'
    }
));

export default function Nav({ openNav, onCloseNav }) {
    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'lg');

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pathname ]);

    const renderContent = (
        <Scrollbar
            sx={
                {
                    height: 1,
                    '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
                }
            }
        >
            <Box sx={ { px: 2.5, py: 3, display: 'inline-flex' } }>
                <Logo/>
            </Box>

            <NavSection data={ navConfig }/>

            <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative', bottom: 0 }}>
                    <SupportLabel></SupportLabel>

                    <a href="https://www.buymeacoffee.com/kataquiz">
                        <img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=kataquiz&button_colour=5F7FFF&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
                             alt="Buy Me A Coffee"
                             style={ { height: "60px !important", width: "217px !important" } }/>
                    </a>
                </Stack>
            </Box>
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={ {
                flexShrink: { lg: 0 },
                width: { lg: NAV_WIDTH },
            } }
        >
            { isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={ {
                        sx: {
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    } }
                >
                    { renderContent }
                </Drawer>
            ) : (
                <Drawer
                    open={ openNav }
                    onClose={ onCloseNav }
                    ModalProps={ {
                        keepMounted: true,
                    } }
                    PaperProps={ {
                        sx: { width: NAV_WIDTH },
                    } }
                >
                    { renderContent }
                </Drawer>
            ) }
        </Box>
    );
}
