import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";

export class UserStatisticsService {
    static userStatistics(): Promise<UserStatistics> {
        return fetch(`${ ENV.BACKEND }/user-statistics`, {
            method: 'GET',
            headers: buildHeaders()
        })
            .then((response) => handleExpiredLogin(response).json());
    }

    static userCalendar(): Promise<UserCalendar> {
        return fetch(`${ ENV.BACKEND }/user-statistics/current/calendar`, {
            method: 'GET',
            headers: buildHeaders()
        })
            .then((response) => handleExpiredLogin(response).json());
    }
}

export interface UserStatistics {
    availableFreezes: number;
    weeklyQuestions: number;
    weeklyWorkouts: number;
    weeklyTopics: number;
    daysInStreak: number;
    overallExperience: number;

    currentWeek: TopicWorkoutsResponse[];
    previousWeek: TopicWorkoutsResponse[];

    workoutsByWeek: Map<string, number>;
}

export interface TopicWorkoutsResponse {
    id: string;
    name: string;
    workouts: number;
}

interface CalendarDay {
    date: string;
    workouts: number;
    freezeLeft: number;
    streak: number;
    freezeAdded: boolean;
    freezeUsed: boolean;
}

export interface UserCalendar {
    items: CalendarDay[];
}
