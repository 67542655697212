import { Helmet } from "react-helmet-async";
import ENV from "../env";
import {
    Avatar,
    Card,
    Collapse,
    Container, Divider,
    Grid, Link,
    List,
    ListItem, ListItemAvatar, ListItemText,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import PageHeader from "../components/text/PageHeader";
import { getTopic, LESSONS } from "../_mock/topics";
import { TimelineLessonGroupText } from "../components/topics/TimelineLessonGroupText";
import { TimelineLessonText } from "../components/topics/TimelineLessonText";
import { useEffect, useState } from "react";
import TimelineGroup from "../components/topics/TimelineGroup";
import TopicTimeline from "../components/topics/TopicTimeline";
import { useNavigate, useParams } from "react-router-dom";
import TopicHeader from "../components/topics/TopicHeader";
import Header from "../components/text/Header";
import LanguageIcon from '@mui/icons-material/Language';
import { LoadingButton } from "@mui/lab";
import WorkoutService from "../api/workout-service";
import { UserProgressService } from "../api/user-progress-service";

export default function TopicPage() {
    const [ selectedGroup, setSelectedGroup ] = useState(null);
    const [ selectedLesson, setSelectedLesson ] = useState(null);
    const [ verifyLoading, setVerifyLoading ] = useState(false);
    const [ userProgress, setUserProgress ] = useState(null);
    const [ selectedLessonProgress, setSelectedLessonProgress ] = useState({});
    const [ selectedLessonProgressStep, setSelectedLessonProgressStep ] = useState(0);

    const { id } = useParams();
    const navigate = useNavigate();

    const topic = getTopic(id);
    const lessons = LESSONS[id] || [];

    function groupSwitch(topicCode) {
        console.trace('groupSwitch', topicCode);
        if (selectedGroup === topicCode) {
            setSelectedGroup(null);
            return;
        }

        setSelectedGroup(topicCode);
    }

    function lessonSwitch(lesson) {
        console.trace('lessonSwitch', lesson);
        if (selectedLesson === lesson) {
            setSelectedLesson(null);
            return;
        }

        setSelectedLesson(lesson);
    }

    function startVerify() {
        setVerifyLoading(true);

        console.log("Start new workout for lesson: " + selectedLesson);
        WorkoutService.startNewWorkout(selectedLesson.title, 7, [ topic.code ], [ selectedLesson.code ], [], 1)
            .then(response => {
                if (!response.id) {
                    alert("Error: Sorry it looks like currently we are not able to start workout for you! Please try again later.");
                    return;
                }

                console.log("Workout started: " + response.id);
                navigate('/workouts/' + response.id);
            })
            .catch(reason => {
                console.error("Unable to start workout: ", reason);
            })
            .finally(() => {
                setVerifyLoading(false);
            });
    }

    useEffect(() => {
        UserProgressService.userProgress(id).then(data => {
            console.log("User progress: ", data);
            setUserProgress(data);
        });
    }, []);

    useEffect(() => {
        setSelectedLessonProgress({});
        setSelectedLessonProgressStep(0);

        if (!userProgress || !selectedLesson) return;

        const select = userProgress.lessons[selectedLesson.code];
        console.log("Progress of selected lesson", select);

        if (!select) return;

        setSelectedLessonProgress(select);

        setSelectedLessonProgressStep(select
            .map(item => item.completed ? 1 : 0)
            .reduce((a, b) => a + b, 0));
    }, [ userProgress, selectedLesson ])

    useEffect(() => {
        if (!lessons.length) {
            navigate("/404");
        }
    }, [])

    useEffect(() => {
        if (!selectedGroup) setSelectedLesson(null);
    }, [ selectedGroup ]);

    return <>
        <Helmet>
            <title>Topic: { topic.title } | { ENV.APP_NAME }</title>
        </Helmet>

        <Container>
            <PageHeader title={ `Topic: ${ topic.title }` }/>

            <Grid container>
                <Grid item xs={ 6 }>
                    <TopicTimeline>
                        { lessons.map(group => (
                            <TimelineGroup key={ group.code } group={ group }
                                           selectedGroup={ selectedGroup } groupSwitch={ groupSwitch }
                                           lessonSwitch={ lessonSwitch }/>
                        )) }
                    </TopicTimeline>
                </Grid>

                <Grid item xs={ 6 }>
                    <Card>
                        <TopicHeader topic={ topic }/>

                        <Stack spacing={ 3 } margin={ '20px' }>
                            <Collapse in={ !selectedLesson }>
                                <Header text="Choose Lesson"/>
                            </Collapse>

                            <Collapse in={ !!selectedLesson }>
                                <Header text={ `${ selectedGroup?.title } - ${ selectedLesson?.title }` }/>

                                <Typography>
                                    { selectedLesson?.description }
                                </Typography>

                                <Divider>Materials</Divider>

                                <List sx={ { width: '100%', maxWidth: 360, bgcolor: 'background.paper' } }>
                                    { selectedLesson?.references?.map(ref => (
                                        <ListItem key={ ref.url }>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <LanguageIcon/>
                                                </Avatar>
                                            </ListItemAvatar>

                                            <ListItemText primary={
                                                <Link href={ ref.url } target="_blank"
                                                      rel="noopener">{ ref.title }</Link>
                                            }/>
                                        </ListItem>
                                    )) }
                                </List>

                                { !selectedLesson?.blocked && (<>
                                    <Divider sx={ { marginBottom: '20px' } }>Progress</Divider>

                                    { selectedLessonProgressStep === 0 ? (
                                        <LoadingButton
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            loading={ verifyLoading }
                                            onClick={ startVerify }>

                                            Verify
                                        </LoadingButton>
                                    ) : (
                                        <LoadingButton fullWidth variant="contained" disabled
                                                       sx={ { cursor: 'not-allowed' } }>
                                            Refresh (Will be available after one week)
                                        </LoadingButton>
                                    ) }

                                    <Stepper activeStep={ selectedLessonProgressStep } orientation="vertical"
                                             sx={ { marginTop: '20px' } }>
                                        <Step>
                                            <StepLabel>Learn and Verify</StepLabel>
                                        </Step>

                                        <Step>
                                            <StepLabel>Refresh after 1 week</StepLabel>
                                        </Step>

                                        <Step>
                                            <StepLabel>Refresh after 1 month</StepLabel>
                                        </Step>
                                    </Stepper>
                                </>) }
                            </Collapse>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </>;
}