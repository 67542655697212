import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";

export default class WorkoutService {
    static startNewWorkout(workoutTitle, targetAmount, topics, lessons = [], tags = [], verify = null) {
        return fetch(`${ ENV.BACKEND }/workout/start-workout`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify({ workoutTitle, targetAmount, topics, lessons, tags, verifySessionIndex: verify }),
        })
            .then((response) => handleExpiredLogin(response).json());
    }

    static submitAnswer(workoutId, questionId, answerIndex) {
        return fetch(`${ ENV.BACKEND }/workout/submit-answer`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify({ workoutId, questionId, answerIndex }),
        })
            .then(response => handleExpiredLogin(response).json());
    }

    static joinWorkout(workoutId) {
        return fetch(`${ ENV.BACKEND }/workout/join-workout`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify({ workoutId }),
        })
            .then((response) => handleExpiredLogin(response).json())
            .then((data) => {
                return data;
            });
    }

    static getWorkout(workoutId) {
        return fetch(`${ ENV.BACKEND }/workout/${ workoutId }`, {
            method: 'GET',
            headers: buildHeaders()
        })
            .then((response) => handleExpiredLogin(response).json());
    }

    static getWorkouts() {
        return fetch(`${ ENV.BACKEND }/workout`, {
            method: 'GET',
            headers: buildHeaders()
        })
            .then((response) => handleExpiredLogin(response).json());
    }

    static finishWorkout(workoutId) {
        return fetch(`${ ENV.BACKEND }/workout/finish-workout`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify({ workoutId }),
        })
            .then(response => handleExpiredLogin(response).json());
    }
}