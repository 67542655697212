import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";

export class InsideService {
    static tagsRate(topicId) {
        return fetch(`${ ENV.BACKEND }/inside/${ topicId }/tags-rate`, {
            method: 'GET',
            headers: buildHeaders()
        })
            .then((response) => handleExpiredLogin(response).json());
    }
}