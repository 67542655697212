import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";

export class UserProgressService {
    static userProgress(topicId) {
        return fetch(`${ ENV.BACKEND }/user-progress/${ topicId }`, {
            method: 'GET',
            headers: buildHeaders()
        })
            .then((response) => handleExpiredLogin(response).json());
    }
}