import { Divider } from "@mui/material";
import UserList from "../user/UserList";

export default function WorkoutUsersBlock({ users, answers, show }) {
    if (!show) return <></>

    return <>
        <Divider style={ { marginTop: '50px' } }>Users</Divider>

        <UserList users={ users } answers={ answers }/>
    </>;
}