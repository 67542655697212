import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, IconButton, InputAdornment, TextField } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Header from "../components/text/Header";
import ENV from "../env";
import LoginSidePanel from "../components/sides/LoginSidePanel";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

import Iconify from "../components/iconify";
import ProfileService from "../api/profile-service";
import { useNavigate } from "react-router-dom";
import FormSavedBanner from "../components/forms/FormSavedBanner";
import FormErrorBanner from "../components/forms/FormErrorBanner";

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export default function RegisterPage(): JSX.Element {
    const mdUp: boolean = useResponsive('up', 'md');
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [idError, setIdError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const [saved, setSaved] = useState(false);

    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false);

    const [saving, setSaving] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const idChanged = event => {
        setId(event.target.value);
        setIdError(!validateId(event.target.value));
    };

    const nameChanged = event => {
        setName(event.target.value)
        setNameError(!validateName(event.target.value));
    };

    const emailChanged = event => {
        setEmail(event.target.value);
        setEmailError(!validateEmail(event.target.value));
    };

    const passwordChanged = event => {
        setPassword(event.target.value);
        setPasswordError(!validatePassword(event.target.value));
    };

    const validateId = (id): boolean => {
        return id !== '' && id.length >= 4 && id.length <= 25;
    };

    const validateName = (name): boolean => {
        return name !== '' && name.length >= 2 && name.length <= 50;
    };

    const validateEmail = (email): boolean => {
        return emailPattern.test(email);
    };

    const validatePassword = (password) => {
        return password !== '' && password.length >= 6 && password.length <= 50;
    };

    const saveAction = (): void => {
        ProfileService.register(
            {id, name, email, password},
            (data): void => {
                if (data.userAlreadyExists) {
                    setError([
                        "User ID is not unique. Please choose another one."
                    ]);
                    setShowError(true);
                    return;
                }

                setShowError(false);
                setSaved(true);

                setTimeout((): void => {
                    navigate('/login');
                }, 5_000);
            },
            (data): void => {
                setError(data.map(error => error.message));
                setShowError(true);
            },
            (): void => {
                setError([
                    "An error occurred while registering. Please try again later."
                ]);
                setShowError(true);
            }
        );
    }

    return (
        <>
            <Helmet>
                <title>Register | { ENV.APP_NAME }</title>
            </Helmet>

            <StyledRoot>
                { mdUp && <LoginSidePanel/> }

                <Container maxWidth="sm">
                    <StyledContent>
                        <Header text="Register"/>

                        <FormSavedBanner show={ saved } message="Registration done successfully! You will be redirected to the login page in 5 seconds." />
                        <FormErrorBanner show={ showError } message={ error }/>

                        <TextField label="User ID" variant="outlined" fullWidth margin="normal" required
                                   value={ id } onChange={ idChanged } />

                        <TextField label="User Name" variant="outlined" fullWidth margin="normal" required
                                   value={ name } onChange={ nameChanged }/>
                        <TextField label="Email" variant="outlined" fullWidth margin="normal" type="email" required
                                   value={ email } onChange={ emailChanged }/>
                        <TextField label="Password"
                                   type={ showPassword ? 'text' : 'password' }
                                   required
                                   variant="outlined"
                                   fullWidth
                                   margin="normal"
                                   value={ password }
                                   onChange={ passwordChanged }
                                   InputProps={ {
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               <IconButton onClick={ () => setShowPassword(!showPassword) }
                                                           edge="end">
                                                   <Iconify
                                                       icon={ showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill' }/>
                                               </IconButton>
                                           </InputAdornment>
                                       )
                                   } }/>

                        <LoadingButton
                            size="large"
                            style={ { width: '200px' } }
                            type="submit"
                            variant="contained"
                            loading={ saving }
                            disabled={ emailError || passwordError || id === ''|| password === '' || email === '' }
                            onClick={ saveAction }>

                            Register
                        </LoadingButton>
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
