import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDxadaroCPKsx8sAjA3yS9RF6zzWLb1czY",
    authDomain: "kataquiz.firebaseapp.com",
    projectId: "kataquiz",
    storageBucket: "kataquiz.appspot.com",
    messagingSenderId: "161180294474",
    appId: "1:161180294474:web:6b1597034a52d0ebe1b5b3"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

function getFirebaseToken(callback: (result: RequestPermissionResult) => void) {
    const vapidKey = 'BICvZSVN0MAYX2MtnBCnsu0umSRT0jny5Ai4JIcBqCbzluj4cLY488fGBEY1N9inVXSTjpRuBo8GaqN7LEEG0VQ';
    getToken(messaging, { vapidKey })
        .then(token => callback({
            type: token ? RequestPermissionResultType.SUCCESS : RequestPermissionResultType.FAILED,
            token
        }));
}

export function isNotificationSupported(): boolean {
    return "Notification" in window;
}

export function isNotificationBlocked(): boolean {
    return Notification.permission === "denied";
}

export function requestNotificationPermission(callback: (result: RequestPermissionResult) => void) {
    Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            console.log("Notification permission granted.");
            getFirebaseToken(callback);
        } else if (permission === "denied") {
            console.log("Unable to get permission to notify.");
            callback({ type: RequestPermissionResultType.DENIED });
        } else {
            Notification.requestPermission().then(permission => {
                if (permission === "denied") {
                    console.log("Unable to get permission to notify.");
                    callback({ type: RequestPermissionResultType.DENIED });
                    return;
                }

                if (permission === "granted") {
                    console.log("Notification permission granted.");
                    getFirebaseToken(callback);
                }
            });
        }
    });
}

export interface RequestPermissionResult {
    type: RequestPermissionResultType;
    token?: string;
}

export enum RequestPermissionResultType {
    SUCCESS, DENIED, FAILED
}