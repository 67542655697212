import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import TimelineStart from "./TimelineStart";
import TimelineEnd from "./TimelineEnd";

export default function TopicTimeline({ children }) {
    return <Timeline sx={ {
        [`& .${ timelineOppositeContentClasses.root }`]: {
            flex: 0.2,
        },
    } }>
        <TimelineStart/>

        { children }

        <TimelineEnd/>
    </Timeline>
}