import { TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";

export default function TimelineEnd() {
    return <TimelineItem>
        <TimelineSeparator>
            <TimelineDot/>
        </TimelineSeparator>

        <TimelineContent>
            ...
        </TimelineContent>
    </TimelineItem>;
}