import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
        <Box
            ref={ ref }
            component="div"
            sx={ {

                display: 'inline-flex',
                ...sx,
            } }
            { ...other }
        >
            <img src="/assets/logo/Logo-sm.png" alt="KataQuiz"/>
        </Box>
    );

    if (disabledLink) {
        return <>{ logo }</>;
    }

    return (
        <Link to="/" component={ RouterLink } sx={ { display: 'contents' } }>
            { logo }
        </Link>
    );
});

Logo.propTypes = {
    sx: PropTypes.object,
    disabledLink: PropTypes.bool,
};

export default Logo;
