import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Link, Card, Grid, CardContent, Typography, Box, Tooltip } from '@mui/material';
import Iconify from "../iconify";
import { fShortenNumber } from "../../utils/formatNumber";
import useResponsive from "../../hooks/useResponsive";

const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
});

const StyledInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    color: "#c1cedb",
}));

const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

TopicCard.propTypes = {
    topic: PropTypes.object.isRequired,
    index: PropTypes.number,
};

export default function TopicCard({ topic, titleClickAction }) {
    const { cover, title, size, rate } = topic;

    const isMobile = useResponsive('down', 'md');

    return (
        <Grid item xs={ 12 } md={ 4 } sm={ 6 }>
            <Card sx={ { position: 'relative' } }>
                <StyledCardMedia
                    sx={ {
                        ...({
                            pt: isMobile ? 'calc(100% * 2 / 3)' : 'calc(100% * 4 / 3)',
                            cursor: 'pointer',
                            '&:after': {
                                top: 0,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
                            },
                        }),
                        ...({
                            pt: {
                                xs: 'calc(100% * 2 / 3)',
                                sm: 'calc(100% * 3 / 4.66)',
                            },
                        }),
                    } }
                >

                    <StyledCover alt={ title } src={ cover }/>
                </StyledCardMedia>

                <CardContent
                    sx={ {
                        pt: 4,
                        ...({
                            bottom: 0,
                            width: '100%',
                            position: 'absolute',
                        }),
                    } }
                >
                    <StyledTitle
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        onClick={ titleClickAction }
                        sx={ {
                            ...({ typography: 'h5', height: 60 }),
                            ...({
                                color: 'common.white',
                                cursor: 'pointer',
                            }),
                        } }
                    >
                        { size && size < 100 ? `${ title } [PREVIEW]` : title }
                    </StyledTitle>

                    { topic.size && (
                        <StyledInfo>
                            <Box
                                sx={ {
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: 0
                                } }
                            >
                                <Iconify icon="eva:question-mark-circle-fill"
                                         sx={ { width: 16, height: 16, mr: 0.5 } }/>

                                <Tooltip title="Amount of question">
                                    <Typography variant="caption">{ fShortenNumber(size) }</Typography>
                                </Tooltip>

                                <Iconify icon="eva:checkmark-circle-2-fill"
                                         sx={ { width: 16, height: 16, mr: 0.5, ml: 1 } }/>

                                <Tooltip title="Rate of correct answers">
                                    <Typography variant="caption">{ rate || ' - ' }%</Typography>
                                </Tooltip>
                            </Box>
                        </StyledInfo>
                    ) }
                </CardContent>
            </Card>
        </Grid>
    );
}
