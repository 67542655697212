import { useEffect, useState } from "react";
import QuestionService from "../../api/question-service";
import { Rating, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export function RatingReport({ questionId, workoutId }) {
    const [ rating, setRating ] = useState(-1);

    useEffect(() => {
        setRating(-1);
    }, [ questionId, workoutId ]);

    function submitRating(newRating) {
        if (newRating === rating) return;

        console.log(`Submitting rating: ${ rating } -> ${ newRating }`);
        setRating(newRating);

        QuestionService.giveFeedback(
            { questionId: questionId, workoutId: workoutId, complaint: false, satisfyGrade: newRating },
            () => console.log('Feedback submitted'),
            () => console.log('Feedback submission failed')
        );
    }

    return <>
        <Typography component="legend" sx={ { marginTop: '25px' } }>
            How do you rate that question?
        </Typography>

        <Rating
            value={ rating }
            emptyIcon={ <StarIcon style={ { opacity: 0.55, color: 'white' } } fontSize="inherit"/> }
            onChange={ (event, newValue) => submitRating(newValue) }
        />

        <Typography sx={ { marginTop: '5px', fontSize: '75%' } }>
            Question id: { questionId }.
        </Typography>
    </>;
}