import { Helmet } from 'react-helmet-async';
import { Card, Container, Grid, IconButton, InputAdornment, TextField, } from '@mui/material';
import ENV from "../env";
import PageHeader from "../components/text/PageHeader";
import { useEffect, useState } from "react";
import ProfileService from "../api/profile-service";
import Iconify from "../components/iconify";
import { LoadingButton } from "@mui/lab";
import FormSavedBanner from "../components/forms/FormSavedBanner";
import FormErrorBanner from "../components/forms/FormErrorBanner";

export default function ProfilePage() {
    const [ profile, setProfile ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ saved, setSaved ] = useState(false);
    const [ error, setError ] = useState(<></>);
    const [ showError, setShowError ] = useState(false);

    const [ id, setId ] = useState("");
    const [ name, setName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const [ showPassword, setShowPassword ] = useState(false);

    useEffect(() => {
        ProfileService.getSelf().then(data => {
            console.log("User profile loaded", data);

            setProfile(data || {});
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setId(profile.id || "");
        setName(profile.name || "");
        setEmail(profile.email || "");
        setPassword(profile.password || "");
    }, [ profile ])

    const nameChanged = event => setName(event.target.value)
    const emailChanged = event => setEmail(event.target.value);
    const passwordChanged = event => setPassword(event.target.value);

    const saveAction = () => {
        setSaving(true);
        setSaved(false);
        setShowError(false);

        ProfileService.update(
            {
                id: id,
                name: name || null,
                email: email || null,
                password: password || null
            },
            result => {
                setSaving(false);
                setSaved(true);
                setProfile(result || {})
            },

            result => {
                setSaving(false);

                setShowError(true);
                setError(
                    <>
                        Please check your input:
                        <ul>
                            { result.map(error => <li><strong>{ error.field }:</strong> { error.message }</li>) }
                        </ul>
                    </>);
            },

            error => {
                setSaving(false);
                console.error("Error saving profile", error);
            }
        );
    };

    return (
        <>
            <Helmet>
                <title>Profile | { ENV.APP_NAME }</title>
            </Helmet>

            <Container>
                <PageHeader title="Profile"/>

                <Card style={ { padding: '20px' } }>
                    <FormSavedBanner show={ saved }/>
                    <FormErrorBanner show={ showError } message={ error }/>

                    <Grid container>
                        <Grid item md={ 8 }>
                            <TextField label="User ID" variant="outlined" fullWidth margin="normal"
                                       value={ id }/>

                            <TextField label="User Name" variant="outlined" fullWidth margin="normal"
                                       value={ name } onChange={ nameChanged }/>
                            <TextField label="Email" variant="outlined" fullWidth margin="normal"
                                       value={ email } onChange={ emailChanged }/>
                            <TextField label="Password"
                                       type={ showPassword ? 'text' : 'password' }
                                       variant="outlined"
                                       fullWidth
                                       margin="normal"
                                       onChange={ passwordChanged }
                                       InputProps={ {
                                           endAdornment: (
                                               <InputAdornment position="end">
                                                   <IconButton onClick={ () => setShowPassword(!showPassword) }
                                                               edge="end">
                                                       <Iconify
                                                           icon={ showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill' }/>
                                                   </IconButton>
                                               </InputAdornment>
                                           )
                                       } }/>

                            <LoadingButton
                                size="large"
                                style={ { width: '200px' } }
                                type="submit"
                                variant="contained"
                                loading={ saving }
                                onClick={ saveAction }>

                                Save
                            </LoadingButton>
                        </Grid>

                        <Grid item md={ 4 }>
                            { !loading && (
                                <img src={ `https://robohash.org/${ id }@kata-quiz.com` }
                                     style={ { borderRadius: "50%" } }/>
                            ) }
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </>
    );
}
