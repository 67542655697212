import ENV from "../env";
import { buildHeaders, handleExpiredLogin } from "./utils";

export default class QuestionService {
    static giveFeedback(command: GiveFeedbackCommand, successAction, errorAction) {
        fetch(`${ ENV.BACKEND }/question/give-feedback`, {
            method: 'POST',
            headers: buildHeaders(),
            body: JSON.stringify(command)
        }).then((response: Response) => {
            handleExpiredLogin(response);

            if (response.status === 200) {
                response.json().then(successAction);
                return;
            }

            errorAction()
        }).catch(errorAction);
    }
}

export interface GiveFeedbackCommand {
    questionId: string;
    workoutId: string;
    comment?: string;
    complaint: boolean;
    satisfyGrade?: number;
}