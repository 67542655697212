export const TOPIC_TITLES = [
    // { title: 'Kafka' },
    // { title: 'Spring' },
    { title: 'Java' },
    { title: 'Computer Science & Engineering', code: 'cs' },
    // { title: 'Security' },
    // { title: 'Architecture' },
    // { title: 'AWS' },
    // { title: 'Hibernate' },
    // { title: 'Linux' },
    // { title: 'SQL' },
    // { title: 'MongoDB' },
    // { title: 'JavaScript' },
    // { title: 'React' },
    // { title: 'Angular' },
    // { title: 'Python' },
    // { title: 'Docker' },
    // { title: 'K8s' },
    // { title: 'Git' },
    // { title: 'HTML/CSS' },
];

export const LESSONS = {
    cs: [
        {
            code: 'cs-alg',
            title: 'Data structures & Algorithms',
            lessons: [
                {
                    code: 'cs-alg-complexity',
                    title: 'Complexity',
                    short: 'Big O notation',
                    description: 'Big O notation, time and space complexity, and asymptotic analysis.',
                    active: true,
                    blocked: true,
                    references: [
                        {
                            title: 'Big O notation on Khan Academy',
                            url: 'https://www.khanacademy.org/computing/computer-science/algorithms/asymptotic-notation/a/big-o-notation',
                        },

                        {
                            title: 'Big O notation on GeeksforGeeks',
                            url: 'https://www.geeksforgeeks.org/types-of-asymptotic-notations-in-complexity-analysis-of-algorithms/'
                        }
                    ],
                },

                {
                    code: 'cs-alg-lists',
                    title: 'Collections',
                    short: 'Lists and sets',
                    description: 'Data structures for storing sets of data, including lists, sets and queues.',
                    active: true,
                    blocked: true,
                    references: []
                },

                {
                    code: 'cs-alg-trees',
                    title: 'Trees & Graphs',
                    short: 'Tree data structures',
                    description: 'Tree data structures, including binary trees nd heaps.',
                    active: true,
                    blocked: true,
                    references: []
                },

                {
                    code: 'cs-alg-searching',
                    title: 'Searching',
                    short: 'Searching algorithms',
                    description: 'Searching algorithms, including binary search, breadth-first search, and depth-first search.',
                    active: true,
                    blocked: true,
                    references: []
                },

                {
                    code: 'cs-alg-sorting',
                    title: 'Sorting',
                    short: 'Sorting algorithms',
                    description: 'Sorting algorithms, including bubble sort, merge sort, quick sort, and heapsort.',
                    active: true,
                    blocked: true,
                    references: []
                }
            ]
        },

        {
            code: 'cs-patterns',
            title: 'Patterns',
            lessons: []
        },

        {
            code: 'cs-concurrency',
            title: 'Concurrency',
            lessons: []
        },

        {
            code: 'cs-transactions',
            title: 'Transactions',
            lessons: []
        },

        {
            code: 'cs-microservices',
            title: 'Microservices',
            lessons: [
                {
                    code: 'cs-microservices-databases',
                    title: 'Databases'
                },
                {
                    code: 'cs-microservices-transactions',
                    title: 'Transactions',
                    short: 'Distributed transactions using Saga and 2pc',
                    description: '',
                    active: true,
                    references: [
                        {
                            title: 'Transactions Across Microservices on Baeldung',
                            url: 'https://www.baeldung.com/transactions-across-microservices'
                        },
                        {
                            title: 'Saga Pattern on Baeldung',
                            url: 'https://www.baeldung.com/cs/saga-pattern-microservices'
                        },
                        {
                            title: 'Saga Pattern on Microservices.io',
                            url: 'https://microservices.io/patterns/data/saga.html'
                        }
                    ]
                }
            ]
        },

        {
            code: 'cs-oo',
            title: 'Object-oriented programming',
            lessons: []
        },

        {
            code: 'cs-ddd',
            title: 'Domain Driven Design',
            lessons: [
                {
                    code: 'cs-ddd-intro',
                    title: 'Introduction',
                    short: 'Basic concepts of DDD',
                    description: 'Fundamental concepts of Domain-Driven Design (DDD), including the importance of the domain ' +
                        'model ubiquitous language, bounded contexts, and aggregates. Understanding of the benefits of using DDD, ' +
                        'as well as its potential drawbacks and challenges.',
                    active: true,
                    blocked: true,
                    references: [
                        {
                            title: 'Domain-Driven Design Quickly on InfoQ',
                            url: 'https://www.infoq.com/minibooks/domain-driven-design-quickly/'
                        },
                        {
                            title: 'Domain-Driven Design Fundamentals on Pluralsight',
                            url: 'https://www.pluralsight.com/courses/domain-driven-design-fundamentals'
                        },
                        {
                            title: 'Domain-Driven Design: The Good Parts by Jimmy Bogard',
                            url: 'https://www.youtube.com/watch?v=U6CeaA-Phqo'
                        }
                    ]
                },

                {
                    code: 'cs-ddd-lang',
                    title: 'Ubiquitous Language',
                    short: 'Common language between domain experts and developers',
                    description: 'Ubiquitous Language is a critical aspect of Domain-Driven Design (DDD) that emphasizes ' +
                        'the importance of creating a shared understanding of the domain between domain experts and ' +
                        'developers. By using a common language that accurately reflects the business domain, DDD helps to ' +
                        'bridge the gap between business requirements and technical implementation. This shared language is ' +
                        'not only used in documentation and code, but also in conversations and discussions among ' +
                        'stakeholders, making it a powerful tool for aligning business and technical goals.',
                    active: true,
                    blocked: true,
                    references: [
                        {
                            title: 'Ubiquitous Language by Martin Fowler',
                            url: 'https://martinfowler.com/bliki/UbiquitousLanguage.html',
                        },
                        {
                            title: 'Developing the ubiquitous language',
                            url: 'https://thedomaindrivendesign.io/developing-the-ubiquitous-language/',
                        }
                    ]
                }
            ]
        },

        {
            code: 'cs-fun',
            title: 'Functional Programming',
            lessons: []
        },

        {
            code: 'cs-networking',
            title: 'Networking',
            lessons: []
        }
    ],

    java: [
        {
            code: 'java-date',
            title: 'Date Time API',
            lessons: []
        },
        {
            code: 'java-io',
            title: 'IO & NIO',
            lessons: []
        },
        {
            code: 'java-streams',
            title: 'Streams',
            lessons: []
        },
        {
            code: 'java-concurrency',
            title: 'Concurrency',
            lessons: [
                {
                    code: 'java-concurrency-threads',
                    title: "Threads",
                },
                {
                    code: 'java-concurrency-executors',
                    title: "Executors",
                    short: 'ExecutorService, Callable, Future interfaces',
                    description: "ExecutorService is one of the easiest way to run work asynchronously. In that lesson we " +
                        "suggest to read materials that shows how to use it.",
                    active: true,
                    references: [
                        {
                            title: 'ExecutorService on Baeldung',
                            url: "https://www.baeldung.com/java-executor-service-tutorial"
                        },
                        {
                            title: 'ExecutorService on JavaDoc',
                            url: "https://docs.oracle.com/javase/8/docs/api/java/util/concurrent/ExecutorService.html"
                        },
                        {
                            title: 'Future on JavaDoc',
                            url: "https://docs.oracle.com/javase/8/docs/api/java/util/concurrent/Future.html"
                        },
                        {
                            title: 'Callable on JavaDoc',
                            url: "https://docs.oracle.com/javase/8/docs/api/java/util/concurrent/Callable.html"
                        }
                    ]
                },
                {
                    title: "Synchronization",
                    code: 'java-concurrency-synchronization',
                },
                {
                    title: "Locks & Barriers",
                    code: 'java-concurrency-locks-barriers',
                },
                {
                    title: "Atomic Variables",
                    code: 'java-concurrency-atomic-variables',
                },
                {
                    title: "Collections",
                    code: 'java-concurrency-collections',
                },
                {
                    title: "Fork/Join",
                    code: 'java-concurrency-fork-join',
                },
                {
                    title: "Old methods",
                    code: 'java-concurrency-old-methods',
                },
                {
                    title: "Deadlocks and Prevention",
                    code: 'java-concurrency-deadlocks-prevention',
                }
            ]
        },
        {
            code: 'java-meta',
            title: 'Meta Programming',
            lessons: [
                {
                    code: 'java-meta-reflection',
                    title: 'Reflection',
                    short: 'Dynamic access to fields, methods and annotations.',
                    references: [
                        {
                            title: 'Java Reflection on Baeldung',
                            url: "https://www.baeldung.com/java-reflection"
                        }
                    ]
                },
                {
                    code: 'java-meta-annotations',
                    title: 'Annotations',
                    short: 'Custom annotations.',
                    references: [
                        {
                            title: 'Java Annotations on Baeldung',
                            url: "https://www.baeldung.com/java-custom-annotation"
                        }
                    ]
                },
                {
                    code: 'java-meta-proxy',
                    title: 'Proxy',
                    short: 'Dynamic proxy creation for interfaces.',
                    references: [
                        {
                            title: 'Java Dynamic Proxy on Baeldung',
                            url: "https://www.baeldung.com/java-dynamic-proxies"
                        }
                    ],
                },
                {
                    code: 'java-meta-cglib',
                    title: 'CGLIB',
                    short: 'Dynamic proxy creation for classes.',
                    references: [
                        {
                            title: 'CGLIB on Baeldung',
                            url: "https://www.baeldung.com/cglib"
                        }
                    ]
                }

            ]
        }
    ]
};

const TOPICS = TOPIC_TITLES.map(item => ({
    cover: `/assets/images/covers/${ item.code || item.title.toLowerCase() }-s.jpg`,
    title: item.title,
    code: item.code || item.title.toLowerCase()
}));

export function getTopic(code) {
    return TOPICS.find(topic => topic.code === code);
}

export default TOPICS;
