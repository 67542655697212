import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import {
    Box,
    Card,
    Container, Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import ENV from "../env";
import Avatar from "../components/user/Avatar";
import PageHeader from "../components/text/PageHeader";
import WorkoutService from "../api/workout-service";
import { fDateTime } from "../utils/formatTime";
import TopicCard from "../components/topics/TopicCard";
import { useNavigate } from "react-router-dom";
import { WorkoutDefinitionsService } from "../api/workout-definitions-service";

const TABLE_HEAD = [
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'topic', label: 'Topic', alignRight: false },
    { id: 'users', label: 'Users', alignRight: false }
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [ el, index ]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function WorkoutsPage() {
    const navigate = useNavigate();

    const [ page, setPage ] = useState(0);
    const [ order, setOrder ] = useState('asc');
    const [ selected, setSelected ] = useState([]);
    const [ orderBy, setOrderBy ] = useState('name');
    const [ filterName, setFilterName ] = useState('');
    const [ rowsPerPage, setRowsPerPage ] = useState(5);
    const [ workouts, setWorkouts ] = useState([]);
    const [ workoutDefinitions, setWorkoutDefinitions ] = useState([]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - workouts.length) : 0;

    const filteredWorkout = applySortFilter(workouts, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredWorkout.length && !!filterName;

    useEffect(() => {
        WorkoutService.getWorkouts().then(data => {
            console.log("Workouts history loaded: ", data);
            setWorkouts(data);
        });
    }, []);

    useEffect(() => {
        WorkoutDefinitionsService.workoutDefinitions().then(data => {
            console.log("Workout definitions loaded", data);
            setWorkoutDefinitions(data);
        });
    }, []);

    function startWorkoutOfTopic(definition) {
        console.log("Start new workout for definition:", definition);
        WorkoutService.startNewWorkout(definition.title, 5, definition.topics, [], definition.tags)
            .then(response => {
                if (!response.id) {
                    alert("Error: Sorry it looks like currently we are not able to start workout for you! Please try again later.");
                    return;
                }

                console.log("Workout started: " + response.id);
                navigate('/workouts/' + response.id);
            })
            .catch(reason => {
                console.error("Unable to start workout: ", reason);
            });
    }

    return <>
        <Helmet>
            <title>Workouts | { ENV.APP_NAME }</title>
        </Helmet>

        <Container>
            <PageHeader title="Workouts"/>

            <Grid container spacing={ 3 }>
                {
                    workoutDefinitions.map(definition =>
                        <TopicCard key={ definition.id }
                                   topic={ {
                                       cover: `/assets/images/covers/${ definition.id }-s.jpg`,
                                       title: definition.title,
                                       size: definition.size,
                                       rate: definition.rate,
                                       view: 0,
                                       share: 0
                                   } }
                                   titleClickAction={ () => startWorkoutOfTopic(definition) }/>
                    )
                }
            </Grid>


            <Box sx={ { minHeight: '50px' } }></Box>

            <PageHeader title="Workouts History"/>
            <Card>
                <Scrollbar>
                    <TableContainer sx={ { minWidth: 800 } }>
                        <Table>
                            <UserListHead
                                order={ order }
                                orderBy={ orderBy }
                                headLabel={ TABLE_HEAD }
                                rowCount={ 0 }
                                numSelected={ selected.length }
                            />

                            <TableBody>
                                { filteredWorkout.slice(0, 25).map((row) => {
                                    const { id, workoutTitle, created, topics, users } = row;

                                    return (
                                        <TableRow hover key={ id } tabIndex={ -1 } role="checkbox">
                                            <TableCell>{ fDateTime(Date.parse(created)) }</TableCell>
                                            <TableCell align="left">
                                                { workoutTitle || topics.map(topic => topic.name || topic.id).join(', ') }
                                            </TableCell>

                                            <TableCell align="left">
                                                { users.map(user =>
                                                    <Avatar key={ user.id } userId={ user.id }
                                                            sx={ { display: 'inline-block' } }/>
                                                ) }
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) }
                                { emptyRows > 0 && (
                                    <TableRow style={ { height: 53 * emptyRows } }>
                                        <TableCell colSpan={ 6 }/>
                                    </TableRow>
                                ) }
                            </TableBody>

                            { isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={ 6 } sx={ { py: 3 } }>
                                            <Paper sx={ { textAlign: 'center' } }>
                                                <Typography variant="h6" paragraph>Not found</Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) }
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
        </Container>
    </>;
}
