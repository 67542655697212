import { styled } from '@mui/material/styles';

const LoginSidePanel = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: '#f1ecf1',
    backgroundImage: 'url(/assets/robots/robot-01-md.jpg)',
    backgroundPosition: 'bottom',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat'
}));

export default LoginSidePanel;