import Header from "../text/Header";
import Markdown from "../markdown/Markdown";
import { Collapse, Container } from "@mui/material";

export default function HintBlock({ text, refs, show }) {
    if (!show) return <></>

    return <>
        <Header text="Hint" sx={ { marginTop: '25px' } }/>
        <Markdown text={ text }/>

        <Collapse in={ refs?.length > 0 }>
            <Header text="References"/>

            <Container>
                <ul>
                    { refs && refs.map((ref, index) =>
                        <li key={ index }>
                            <a href={ refs[index] } target="_blank">{ refs[index] }</a>
                        </li>)
                    }
                </ul>
            </Container>
        </Collapse>
    </>;
}