import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import TopicCard from '../components/topics/TopicCard';
import TOPICS from '../_mock/topics';
import ENV from "../env";
import PageHeader from "../components/text/PageHeader";
import { useNavigate } from "react-router-dom";

export default function TopicsPage() {
    const navigate = useNavigate();

    return <>
        <Helmet>
            <title>Topics | { ENV.APP_NAME }</title>
        </Helmet>

        <Container>
            <PageHeader title="Topics"/>

            <Grid container spacing={ 3 }>
                {
                    TOPICS.map((topic, index) => (
                        <TopicCard key={ topic.code } topic={ topic }
                                   titleClickAction={ () => navigate(`/topics/${ topic.code }`) }/>
                    ))
                }
            </Grid>
        </Container>
    </>;
}
