import ENV from "../env";
import decodeJwtToken from "../utils/decodeJwtToken";
import { buildHeaders } from "./utils";
import { THEN_DO_NOTHING } from "../utils/callbacks";

export default class AuthService {
    #token = null;
    #tokenData = null;
    #tokenKeyInStorage = 'auth-token'

    constructor() {
        console.log("AuthService initialized");
        this.#token = localStorage.getItem(this.#tokenKeyInStorage);

        if (!this.#token) return;

        this.#tokenData = decodeJwtToken(this.#token);

        fetch(`${ ENV.BACKEND }/system/session`, {
            method: 'GET',
            headers: buildHeaders()
        }).then(response => {
            if (response.status === 401) {
                this.logout(THEN_DO_NOTHING);
            }
        });
    }

    isLoggedIn(): boolean {
        return !!this.#token;
    }

    login(credentials: Credentials,
          callbackSuccess: () => void,
          callbackError: (error: string) => void): void {

        fetch(`${ ENV.BACKEND }/system/authenticate`, {
            method: 'POST',
            headers: buildHeaders(false),
            body: JSON.stringify(credentials)
        }).then(response => {
            if (!response.ok) {
                callbackError('Hmm! It looks like you enter wrong credentials.');
                return;
            }

            console.log("Login success", response);
            this.#token = response.json().then(data => {
                this.#token = data.token;
                this.#tokenData = decodeJwtToken(this.#token);

                localStorage.setItem(this.#tokenKeyInStorage, this.#token);
                callbackSuccess();
            });
        }).catch(error => {
            console.warn(error);
            callbackError("Sorry but we can't connect to the server.");
        });
    }

    logout(callback: () => void): void {
        this.#token = null;

        sessionStorage.clear();
        localStorage.removeItem(this.#tokenKeyInStorage);

        setTimeout(callback);
    }

    get username(): string {
        return this.#tokenData?.sub || '';
    }
}

export interface Credentials {
    username: string;
    password: string;
}