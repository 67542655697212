import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark as CodeStyle } from "react-syntax-highlighter/dist/cjs/styles/prism";
import ReactMarkdown from "react-markdown";

const markdownComponents = {
    code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '')
        return !inline && match ? (
            <SyntaxHighlighter
                children={ String(children).replace(/\n$/, '') }
                style={ CodeStyle }
                language={ match[1] }
                PreTag="div"
                { ...props }
            />
        ) : (
            <code className={ className } { ...props }>
                { children }
            </code>
        )
    }
};

const Markdown = ({ text, show = true }) => {
    if (!show) return <></>;
    return <ReactMarkdown components={ markdownComponents }>{ text }</ReactMarkdown>;
}

export default Markdown